<template>
  <div v-show="isAddNotice == false" class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">공지 관리</div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>
      <n-space style="width: 1700px; min-width: 1700px; height: auto">
        <NCard
          :bordered="false"
          style="
            margin-top: 20px;
            margin-left: 100px;
            width: 1000px;
            min-width: 1000px;
            height: 100%;
          "
        >
        </NCard>
        <NCard
          :bordered="false"
          style="
            margin-top: 20px;
            margin-left: 30px;
            width: 550px;
            min-width: 550px;
            height: 100%;
          "
        >
          <n-space style="height: 100%" justify="end" align="end">
            <NButton
              style="width: 200px; height: 30px; border: none"
              v-on:click="showAddNotice"
            >
              공지 등록
            </NButton>
          </n-space>
        </NCard>
      </n-space>
    </div>
    <div class="outer" style="height: 620px; margin-top: 50px">
      <n-data-table
        class="inner"
        style="margin-left: 100px; width: 1600px; min-width: 1600px; height: 620px"
        ref="table"
        :columns="columns"
        :data="showList"
        :pagination="pagination"
        :max-height="520"
        striped
      />
    </div>
  </div>
  <AddNotice
    v-show="isAddNotice == true"
    @hide="hideAddNotice"
    :isAddNotice="isAddNotice"
    :selectedItem="selectedItem"
    @changeLoadingStatus="changeLoadingStatus"
  >
  </AddNotice>
  <NModal
    v-model:show="isAlert"
    :mask-closable="false"
    :content="alertMessage"
    :type="alertType"
    preset="dialog"
    title="Dialog"
    positive-text="확인"
    @positive-click="onPositiveClick"
  >
  </NModal>
  <NModal v-model:show="isShowContent">
    <n-card
      style="width: 600px"
      title="내용"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      {{ contentMessage }}
    </n-card>
  </NModal>
  <NModal
    v-model:show="isDeleteConfirm"
    :mask-closable="false"
    :content="deleteConfirmMessage"
    :type="deleteConfirmType"
    preset="dialog"
    negative-text="취소"
    positive-text="확인"
    @positive-click="onDeletePositiveClick"
    @negative-click="onDeleteNegativeClick"
  >
  </NModal>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NDataTable,
  NSpace,
  NCheckbox,
  NCard,
  NSelect,
  NButton,
  NIcon,
  NModal,
  NRadio,
  NRadioGroup,
  NInput,
} from "naive-ui";
import AddNotice from "@/views/notice/AddNotice.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import {
  LogOutOutline as LogOutIcon,
  Play as PlayIcon,
  Stop as StopIcon,
  Close as CloseIcon,
} from "@vicons/ionicons5";
const getNotices = getCallable("getNotices");
const deleteNotice = getCallable("deleteNotice");

export default defineComponent({
  name: "Notices",
  emits: ["changeLoadingStatus"],
  components: {
    NDataTable,
    NCheckbox,
    NSpace,
    NInput,
    NCard,
    NSelect,
    NButton,
    NIcon,
    NModal,
    NRadio,
    NRadioGroup,
    AddNotice,
  },
  data() {
    return {
      deleteConfirmType: "error",
      deleteConfirmMessage: "",
      isDeleteConfirm: false,
      isShowContent: false,
      contentMessage: "",
      contentTitle: "",
      isAlert: false,
      alertMessage: "",
      alertType: "",
      pagination: { pageSize: 100 },
      columns: [
        {
          title: "index",
          key: "index",
          sorter: "default",
        },
        {
          title: "제목",
          key: "title",
          sorter: "default",
        },
        {
          title: "내용",
          sorter: "default",
          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.onShowContent(row);
                },
                // bordered: false,
              },
              { default: () => row.parseContent }
            );
          },
        },
        {
          title: "작성일",
          key: "parseCreatedDate",
          sorter: "default",
        },
        {
          title: "삭제",
          sorter: "default",
          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.onDelete(row);
                },
                // bordered: false,
              },
              { default: () => "삭제" }
            );
          },
        },
      ],
      baseList: [] as any,
      showList: [] as any,
      deleteItem: undefined as any,
      isAddNotice: false as boolean,
      selectedItem: undefined as any,
    };
  },
  setup() {
    const tableRef = ref(null);
  },
  mounted() {
    this.getNoticeList();
  },
  methods: {
    confirmDelete(row: any) {
      this.deleteItem = row;
      this.deleteConfirmMessage = "삭제 하시겠습니까?";
      this.isDeleteConfirm = true;
    },
    onDeletePositiveClick() {
      this.deleteNotice();
      this.isDeleteConfirm = false;
    },
    onDeleteNegativeClick() {
      this.isDeleteConfirm = false;
      this.deleteItem = undefined;
    },
    onShowContent(row: any) {
      this.isShowContent = true;
      this.contentMessage = row.content;
    },
    changeLoadingStatus(status: any) {
      this.$emit("changeLoadingStatus", status);
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    showAddNotice() {
      this.isAddNotice = true;
    },
    hideAddNotice() {
      this.selectedItem = undefined;
      this.isAddNotice = false;
      this.getNoticeList();
    },
    deleteNotice() {
      if (this.deleteItem == undefined) {
        this.alertMessage = "삭제 실패";
        this.alertType = "error";
        this.isAlert = true;
        return;
      }
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        deleteNotice({
          id: this.deleteItem.SK,
        })
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "삭제 실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              this.alertMessage = "삭제 성공";
              this.alertType = "success";
              this.isAlert = true;
              this.getNoticeList();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.deleteItem = undefined;
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    getNoticeList() {
      this.baseList = [];
      this.showList = [];
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        getNotices({})
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              let i = 0;
              for (let item of result.data.data.Items) {
                if (item.createdDate != undefined)
                  item.parseCreatedDate = common.getDate(item.createdDate);
                item.parseContent = common.parseContent(item.content);
                item.onShowContent = this.onShowContent;
                item.onDelete = this.confirmDelete;
                item.index = i++;
                this.baseList.push(item);
              }
              this.showList = this.baseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
