<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">관리자 관리</div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px"> </NCard>
      </n-space>
    </div>
    <div class="outer" style="height: 620px; margin-top: 50px">
      <n-data-table
        class="inner"
        style="margin-left: 100px; width: 1600px; min-width: 1600px; height: 620px"
        ref="table"
        :columns="columns"
        :data="showList"
        :pagination="pagination"
        :max-height="520"
        striped
      />
    </div>
  </div>
</template>

<script lang="ts">
import { h, defineComponent, ref } from 'vue';
import { getCallable, firebaseStorage } from '@/config/env';
import { NDataTable, NSpace, NCard, NButton, NIcon, NModal, NInput, commonLight } from 'naive-ui';
import * as common from '@/assets/common/common';
import * as constant from '@/assets/constant';
import { LogOutOutline as LogOutIcon, Play as PlayIcon, Stop as StopIcon, Close as CloseIcon } from '@vicons/ionicons5';

const getAdminMembers = getCallable('getAdminMembers');
const modifyAdminMember = getCallable('modifyAdminMember');
export default defineComponent({
  name: 'Member',
  components: {
    NDataTable,
    NSpace,
    NInput,
    NCard,
    NButton,
    NIcon,
    NModal,
  },
  data() {
    return {
      isAlert: false,
      alertMessage: '',
      alertType: '',
      searchInnaId: '',
      pagination: { pageSize: 50 },
      columns: [
        {
          title: 'index',
          key: 'index',
          sorter: 'default',
        },
        {
          title: 'uid',
          key: 'uid',
          sorter: 'default',
        },
        {
          title: '이름',
          key: 'name',
          sorter: 'default',
        },
        {
          title: '잠금 여부',
          key: 'isLockText',
          sorter: 'default',
          render(row: any) {
            return h(
              NButton,
              {
                size: 'small',
                onClick: () => {
                  row.onChangeIsLockStatus(row);
                },
                // bordered: false,
              },
              { default: () => row.isLockText }
            );
          },
        },
        {
          title: '가입 일시',
          key: 'parseCreatedDate',
          sorter: 'default',
        },
      ],
      baseList: [] as any,
      showList: [] as any,
    };
  },
  setup() {
    const tableRef = ref(null);
  },
  beforeUnmount() {},
  mounted() {
    this.getMemberList();
  },
  methods: {
    onChangeIsLockStatus(row: any) {
      this.$emit('changeLoadingStatus', true);
      let changeIsLock = row.isLock == true ? false : true;
      common.asyncFunction(async () => {
        modifyAdminMember({
          id: row.SK,
          isLock: changeIsLock,
        })
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = '수정 실패';
              this.alertType = 'error';
              this.isAlert = true;
            } else {
              row.isLock = changeIsLock;
              row.isLockText = common.getIsLockText(changeIsLock);
            }
          })
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit('changeLoadingStatus', false);
          });
      });
    },
    getMemberList() {
      this.baseList = [];
      this.showList = [];
      let currentYear = new Date().getFullYear();
      this.$emit('changeLoadingStatus', true);
      common.asyncFunction(async () => {
        getAdminMembers({})
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              let i = 0;
              for (let item of result.data.data.Items) {
                item.index = i++;
                item.parseCreatedDate = common.getDate(item.createdDate);
                item.isLockText = common.getIsLockText(item.isLock);
                item.onChangeIsLockStatus = this.onChangeIsLockStatus;
                this.baseList.push(item);
              }
              this.showList = this.baseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit('changeLoadingStatus', false);
          });
      });
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
