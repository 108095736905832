<template>
  <div class="main_div">
    <Loading v-show="isLoading" />
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 200px; font-weight: bold">
              발행처 관리
            </div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>

      <n-space style="width: 1700px; min-width: 1700px; height: auto">
        <NCard
          :bordered="false"
          style="
            margin-top: 20px;
            margin-left: 100px;
            width: 1000px;
            min-width: 1000px;
            height: 100%;
          "
        >
        </NCard>
        <NCard
          :bordered="false"
          style="
            margin-top: 20px;
            margin-left: 30px;
            width: 550px;
            min-width: 550px;
            height: 100%;
          "
        >
          <n-space style="height: 100%" justify="end" align="end">
            <NButton
              style="width: 200px; height: 30px; border: none"
              v-on:click="addNewPublisher"
            >
              발행처 등록
            </NButton>
          </n-space>
        </NCard>
      </n-space>
    </div>
    <div class="outer" style="height: 620px; margin-top: 50px">
      <n-data-table
        class="inner"
        style="margin-left: 100px; width: 1600px; min-width: 1600px; height: 620px"
        ref="table"
        :columns="columns"
        :data="showList"
        :pagination="pagination"
        :max-height="520"
        striped
      />
    </div>

    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>

    <NModal
      v-model:show="isDeleteConfirm"
      :mask-closable="false"
      :content="deleteConfirmMessage"
      :type="deleteConfirmType"
      preset="dialog"
      negative-text="취소"
      positive-text="확인"
      @positive-click="onDeletePositiveClick"
      @negative-click="onDeleteNegativeClick"
    >
    </NModal>
  </div>

  <NModal
    v-model:show="isShowManagePublisherDialog"
    :mask-closable="false"
    preset="dialog"
    title="발행처 관리"
    :positive-text="selectedItem ? '수정' : '등록'"
    @positive-click="onPositiveClickManagePublisher"
    style="width: 700px"
    @close="closeManagePublisher"
  >
    <template v-if="alertType === 'errorC'">
      <n-card :bordered="false" role="dialog" aria-modal="true">
        {{ alertMessage }}
      </n-card>
    </template>
    <template v-else>
      <n-card :bordered="false" role="dialog" aria-modal="true">
        <div v-if="isLoading || isFetching" class="loading-overlay">
          <div class="loading-spinner"></div>
        </div>
        <NCard style="height: 100%">
          <n-space item-style="display: flex;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">이름</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="publisherName"
              style="height: 30px; text-align: left"
              :loading="isCheckingDuplicate"
            />
          </n-space>
        </NCard>
      </n-card>
    </template>
  </NModal>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NDataTable,
  NSpace,
  NCard,
  NButton,
  NIcon,
  NModal,
  NInput,
  commonLight,
  NUpload,
  NForm,
  NDatePicker,
  NFormItem,
  FormItemRule,
  NRadio,
  NRadioGroup,
} from "naive-ui";
import Loading from "@/components/Loading.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import axios from "axios";
import type { UploadInst, FormInst } from "naive-ui";

const addPublisher = getCallable("addPublisher");
const modifyPublisher = getCallable("modifyPublisher");
const getPublishers = getCallable("getPublishers");
const deletePublisher = getCallable("deletePublisher");
export default defineComponent({
  name: "Publisher",
  components: {
    NDataTable,
    NSpace,
    NInput,
    NCard,
    NButton,
    NIcon,
    NModal,
    NUpload,
    NForm,
    NDatePicker,
    NFormItem,
    Loading,
    NRadio,
    NRadioGroup,
  },
  data() {
    return {
      isShowManagePublisherDialog: false as boolean,
      publisherName: undefined as any,
      selectedItem: undefined as any,
      selectedItemDetails: undefined as any,
      pagination: { pageSize: 50 },
      columns: [
        {
          title: "index",
          key: "index",
          sorter: "default",
        },
        {
          title: "ID",
          key: "SK",
          sorter: "default",
        },
        {
          title: "이름",
          key: "name",
          sorter: "default",
        },

        {
          title: "수정",

          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.rowOnClick(row);
                },
                // bordered: false,
              },
              { default: () => "수정" }
            );
          },
        },
        {
          title: "삭제",

          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.onDelete(row);
                },
                // bordered: false,
              },
              { default: () => "삭제" }
            );
          },
        },
      ],
      baseList: [] as any,
      showList: [] as any,
      deleteItem: undefined as any,
      deleteConfirmType: "error",
      deleteConfirmMessage: "",
      isDeleteConfirm: false,
      isAlert: false,
      alertMessage: "",
      alertType: "",
    };
  },
  setup() {},
  beforeUnmount() {},
  mounted() {
    this.getPublisherList();
  },
  methods: {
    confirmDelete(row: any) {
      this.deleteItem = row;
      this.deleteConfirmMessage = "삭제 하시겠습니까?";
      this.isDeleteConfirm = true;
    },
    onDeletePositiveClick() {
      this.deletePublisher();
      this.isDeleteConfirm = false;
    },
    onDeleteNegativeClick() {
      this.isDeleteConfirm = false;
      this.deleteItem = undefined;
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    onPositiveClickManagePublisher() {
      if (this.publisherName == undefined) {
        this.alertMessage = "이름을 입력해주세요";
        this.alertType = "error";
        this.isAlert = true;
        return false;
      }
      this.$emit("changeLoadingStatus", true);
      if (this.selectedItem == undefined) {
        common.asyncFunction(async () => {
          addPublisher({
            name: this.publisherName,
          })
            .then(async (result: any) => {
              if (result.data.error) {
                this.alertMessage = "등록 실패";
                this.alertType = "error";
                this.isAlert = true;
              } else {
                this.alertMessage = "등록 성공";
                this.alertType = "success";
                this.isAlert = true;
                this.getPublisherList();
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.$emit("changeLoadingStatus", false);
            });
        });
      } else {
        common.asyncFunction(async () => {
          modifyPublisher({
            id: this.selectedItem.SK,
            name: this.publisherName,
          })
            .then(async (result: any) => {
              if (result.data.error) {
                this.alertMessage = "수정 실패";
                this.alertType = "error";
                this.isAlert = true;
              } else {
                this.alertMessage = "수정 성공";
                this.alertType = "success";
                this.isAlert = true;
                this.selectedItem = undefined;
                this.publisherName = undefined;
                this.getPublisherList();
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.$emit("changeLoadingStatus", false);
            });
        });
      }
    },
    showManageDialog() {
      this.isShowManagePublisherDialog = true;
    },
    addNewPublisher() {
      this.selectedItem = undefined;
      this.publisherName = undefined;
      this.showManageDialog();
    },
    modifyPublisher(row: any) {
      this.selectedItem = row;
      this.publisherName = row.name;
      this.showManageDialog();
    },
    deletePublisher() {
      if (this.deleteItem == undefined) {
        this.alertMessage = "삭제 실패";
        this.alertType = "error";
        this.isAlert = true;
        return;
      }
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        deletePublisher({
          id: this.deleteItem.SK,
        })
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "삭제 실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              this.alertMessage = "삭제 성공";
              this.alertType = "success";
              this.isAlert = true;
              this.getPublisherList();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.deleteItem = undefined;
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    changeLoadingStatus(status: any) {
      this.$emit("changeLoadingStatus", status);
    },

    getPublisherList() {
      this.baseList = [];
      this.showList = [];
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        getPublishers({})
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              let i = 0;
              for (let item of result.data.data.Items) {
                item.rowOnClick = this.modifyPublisher;
                item.onDelete = this.confirmDelete;

                item.index = i++;
                this.baseList.push(item);
              }
              this.showList = this.baseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
