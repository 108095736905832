<template>
  <div class="loading">
    <div class="spinner"></div>
    <p>&nbsp;Loading</p>
  </div>
</template>

<style>
.loading {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background: lightgray; */
  /* opacity: 0.5; */
  z-index: 999;
  backdrop-filter: blur(5px);
  color: #ffffff99;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  gap: 0;
  text-shadow: 0px 0px 5px #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
}

.spinner {
  width: 80px;
  height: 80px;
  border: 8px solid rgba(255, 255, 255, 0.25);
  border-top: 8px solid #ffffff;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 5px rgba(255, 255, 255, 1));
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
