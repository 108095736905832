import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c035827"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "side-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NMenu = _resolveComponent("NMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NMenu, {
      "on-update-value": _ctx.changeSelectedHomeView,
      options: _ctx.options,
      style: {"width":"140px"},
      "default-value": "DashBoard"
    }, null, 8, ["on-update-value", "options"])
  ]))
}