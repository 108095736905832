import * as kakaoConfigJson from "./kakaoConfig.json";

const kakaoConfigs = kakaoConfigJson;

export const kakaoConfig = {
  javaScriptKey: kakaoConfigs.javaScriptKey,
  restApiKey: kakaoConfigs.restApiKey,
  reDirectUri: kakaoConfigs.reDirectUri,
  grantType: kakaoConfigs.grantType,
};
