<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 300px; font-weight: bold">
              프로모션 코드 > 코드 등록
            </div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>

      <n-space style="width: 1400px; min-width: 1400px; height: auto">
        <NCard
          style="
            margin-top: 20px;
            margin-left: 100px;
            width: 100%;
            min-width: 1000px;
            height: 100%;
          "
        >
          <n-form ref="formRef" :model="formValue">
            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">
                프로모션 이름
              </div>
              <n-form-item
                path="name"
                :rule="{
                  required: true,
                  trigger: ['blur', 'input'],
                  message: '프로모션 이름을 입력해주세요',
                }"
              >
                <NInput
                  type="text"
                  placeholder=""
                  v-model:value="formValue.name"
                  style="width: 700px; height: 30px; text-align: left"
                  :allow-input="noSideSpace"
                />
              </n-form-item>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">기간</div>
              <n-form-item
                path="startDate"
                :rule="{
                required: true,
                trigger: ['blur', 'change'],
                message: '시작일을 선택해주세요',
                validator (rule: FormItemRule, value: string) {
            if (!value) {
              return new Error('Date is required')
            } 
            return true
          },
              }"
              >
                <n-date-picker
                  v-model:value="formValue.startDate"
                  :default-value="Date.now()"
                  :is-date-disabled="startDateDisabled"
                />
              </n-form-item>
              <p>~</p>
              <n-form-item
                path="expiryDate"
                :rule="{
                required: true,
                trigger: ['blur', 'change'],
                message: '만료일을 선택해주세요',
                validator (rule: FormItemRule, value: string) {
            if (!value) {
              return new Error('Date is required')
            } 
            return true
          },
              }"
              >
                <n-date-picker
                  v-model:value="formValue.expiryDate"
                  :is-date-disabled="expiryDateDisabled"
                />
              </n-form-item>
            </n-space>

            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">
                활성 여부
              </div>
              <n-form-item path="isUse">
                <n-radio-group v-model:value="formValue.isUse" name="isUse">
                  <n-radio :value="1" style="font-size: 15px">활성</n-radio>
                  <n-radio :value="0" style="font-size: 15px">비활성</n-radio>
                </n-radio-group>
              </n-form-item>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">설명</div>
              <n-form-item path="description">
                <NInput
                  type="textarea"
                  placeholder=""
                  v-model:value="formValue.description"
                  style="width: 900px; height: 200px; text-align: left"
                />
              </n-form-item>
            </n-space>
            <div class="content_delimiter_line"></div>

            <n-space item-style="display: flex;" align="center">
              <div style="font-size: 20px; width: 200px; font-weight: bold">파일</div>
              <n-form-item
                path="file"
                :rule="{
                required: isAdd ? true : false,
                trigger: ['blur', 'change'],
                message: '파일을 선택해주세요',
                validator (rule: FormItemRule, value: any) {
                  if(isAdd){
if (!value || value.length === 0) {
              return new Error('File is required')
            } 
                  }
            
            return true
          },
              }"
              >
                <n-upload
                  ref="uploadPromotionCode"
                  style="width: 300px"
                  :default-upload="false"
                  multiple
                  @change="contentKoSeclect"
                  v-model:value="formValue.file"
                  max="1"
                >
                  <n-button :disabled="!isAdd">Select File</n-button>
                </n-upload>
              </n-form-item>
            </n-space>
            <n-space style="margin-top: 50px" justify="center">
              <n-form-item>
                <NButton
                  dashed
                  style="width: 250px; height: 30px; border: none"
                  type="warning"
                  v-on:click="hide"
                >
                  취소
                </NButton>
              </n-form-item>

              <n-form-item>
                <NButton
                  dashed
                  @click="isAdd ? upload() : modify()"
                  style="width: 250px; height: 30px; border: none"
                  type="success"
                  >{{ submitButtonText }}</NButton
                >
              </n-form-item>
            </n-space>
          </n-form>
        </NCard>
      </n-space>
    </div>

    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
  </div>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NSpace,
  NCard,
  NButton,
  NIcon,
  NModal,
  NInput,
  commonLight,
  NUpload,
  NForm,
  NDatePicker,
  NFormItem,
  FormItemRule,
  NRadio,
  NRadioGroup,
} from "naive-ui";
import Loading from "@/components/Loading.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import axios from "axios";
import type { UploadInst, FormInst } from "naive-ui";
import { promotionFileUploadUrl } from "@/config/fb_env";

const uploadPromotionCsv = getCallable("uploadPromotionCsv");
const modifyPromotion = getCallable("modifyPromotion");

export default defineComponent({
  name: "AddPromotion",
  props: ["isAddPromotion", "selectedItem"],
  components: {
    NSpace,
    NInput,
    NCard,
    NButton,
    NIcon,
    NModal,
    NUpload,
    NForm,
    NDatePicker,
    NFormItem,
    Loading,
    NRadio,
    NRadioGroup,
  },
  watch: {
    isAddPromotion: function (newIsAddPromotion: any) {
      this.sItem = this.selectedItem;

      this.isAdd = this.sItem == undefined;
      if (this.isAdd) {
        this.submitButtonText = "등록";
        this.clearEditor();
      } else {
        this.submitButtonText = "수정";
        this.setDataEditor();
      }
    },
  },
  data() {
    return {
      isAdd: true as boolean,
      isAlert: false as boolean,
      alertType: "",
      alertMessage: "",
      isLoading: false,
      sItem: undefined as any,
      uploadPromotionCodeRef: null,
      formRef: null as any,
      formValue: {
        file: null,
        name: null,
        expiryDate: Date.now(),
        startDate: Date.now(),
        isUse: 0,
        description: "",
      },
      submitButtonText: "등록",
    };
  },
  setup() {
    return {
      noSideSpace: (value: string) => !/ /g.test(value),
    };
  },
  beforeUnmount() {},
  mounted() {
    this.formRef = this.$refs.formRef as any;
  },
  methods: {
    expiryDateDisabled(ts: number) {
      const date = new Date(ts);
      const startDate = new Date(this.formValue.startDate);
      startDate.setHours(0, 0, 0, 0);
      return date < startDate;
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    hide() {
      this.clearEditor();
      this.$emit("hide");
    },
    modify() {
      this.formRef.validate((errors: any) => {
        if (!errors) {
          this.$emit("changeLoadingStatus", true);
          common.asyncFunction(async () => {
            let data = {
              id: this.sItem.SK,
              name: this.formValue.name,
              expiryDate: this.formValue.expiryDate.toString(),
              startDate: this.formValue.startDate.toString(),
              isUse: this.formValue.isUse,
              description: this.formValue.description,
            } as any;

            modifyPromotion(data)
              .then(async (result: any) => {
                if (result.data.error) {
                  this.alertMessage = "수정 실패";
                  this.alertType = "error";
                  this.isAlert = true;
                } else {
                  this.alertMessage = "수정 성공";
                  this.alertType = "success";
                  this.isAlert = true;
                  this.hide();
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.$emit("changeLoadingStatus", false);
              });
          });
        } else {
          console.log("Validation failed:", errors);
        }
      });
    },
    upload() {
      console.log("upll");

      this.formRef.validate((errors: any) => {
        if (!errors) {
          this.$emit("changeLoadingStatus", true);
          console.log("Form submitted:", this.formValue);

          const formData = new FormData();
          if (this.formValue.file) {
            formData.append("file", this.formValue.file);
          }
          if (this.formValue.name) {
            formData.append("name", this.formValue.name);
          }
          if (this.formValue.startDate) {
            formData.append("startDate", this.formValue.startDate.toString());
          }
          if (this.formValue.expiryDate) {
            formData.append("expiryDate", this.formValue.expiryDate.toString());
          }
          if (this.formValue.isUse) {
            formData.append("isUse", this.formValue.isUse.toString());
          }
          if (this.formValue.description) {
            formData.append("description", this.formValue.description);
          }
          axios
            .post(promotionFileUploadUrl, formData)
            .then((response: any) => {
              console.log("Form submitted successfully:", response.data);
              this.isAlert = true;
              if (response.data.message == "success") {
                this.alertType = "success";
                this.alertMessage = "프로모션 코드를 등록되었습니다.";

                this.hide();
              } else if (response.data.message == "failed") {
                if (response.data.code == "1000") {
                  this.alertType = "error";
                  this.alertMessage =
                    "프로모션 이름이 이미 등록되어 있습니다.\n 다른 이름을 입력해주세요.";
                } else {
                  this.alertType = "error";
                  this.alertMessage = "오류가 발생했습니다.";
                }
              }
            })
            .catch((error) => {
              console.error("Form submission failed:", error);
              this.alertType = "error";
              this.alertMessage = "오류가 발생했습니다.";
            })
            .finally(() => {
              this.$emit("changeLoadingStatus", false);
            });
        } else {
          console.log("Validation failed:", errors);
        }
      });
    },
    contentKoSeclect(e: any) {
      this.formValue.file =
        e.fileList.length > 0 ? e.fileList.map((item: any) => item.file)[0] : null;
      if (this.formRef != null) {
        this.formRef.validate();
      }
    },
    clearEditor() {
      this.formValue.name = null;
      this.formValue.startDate = Date.now();
      this.formValue.expiryDate = Date.now();
      this.formValue.isUse = 0;
      this.formValue.description = "";
      this.formValue.file = null;
    },
    setDataEditor() {
      console.log(this.sItem);

      if (this.sItem != undefined) {
        this.formValue.name = this.sItem.name == undefined ? null : this.sItem.name;
        this.formValue.startDate = Date.parse(this.sItem.parseStartDate);
        this.formValue.expiryDate = Date.parse(this.sItem.parseExpDate);
        this.formValue.isUse = this.sItem.isUse == undefined ? 0 : this.sItem.isUse;
        this.formValue.description = this.sItem.description;
      }
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
