<template>
  <div class="main_div" v-show="isAddPromotion == false && isPromotionDetails == false">
    <!-- <Loading v-show="isLoading" /> -->
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 200px; font-weight: bold">
              프로모션 코드
            </div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>

      <n-space style="width: 1700px; min-width: 1700px; height: auto">
        <NCard
          :bordered="false"
          style="
            margin-top: 20px;
            margin-left: 100px;
            width: 1000px;
            min-width: 1000px;
            height: 100%;
          "
        >
        </NCard>
        <NCard
          :bordered="false"
          style="
            margin-top: 20px;
            margin-left: 30px;
            width: 550px;
            min-width: 550px;
            height: 100%;
          "
        >
          <n-space style="height: 100%" justify="end" align="end">
            <NButton
              style="width: 200px; height: 30px; border: none"
              v-on:click="showAddPromotion"
            >
              코드 등록
            </NButton>
          </n-space>
        </NCard>
      </n-space>
    </div>
    <div class="outer" style="height: 620px; margin-top: 50px">
      <n-data-table
        class="inner"
        style="margin-left: 100px; width: 1600px; min-width: 1600px; height: 620px"
        ref="table"
        :columns="columns"
        :data="showList"
        :pagination="pagination"
        :max-height="520"
        striped
      />
    </div>

    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>

    <NModal
      v-model:show="isDeleteConfirm"
      :mask-closable="false"
      :content="deleteConfirmMessage"
      :type="deleteConfirmType"
      preset="dialog"
      negative-text="취소"
      positive-text="확인"
      @positive-click="onDeletePositiveClick"
      @negative-click="onDeleteNegativeClick"
    >
    </NModal>
  </div>

  <AddPromotion
    v-show="isAddPromotion == true"
    @hide="hideAddPromotion"
    :selectedItem="selectedItem"
    :isAddPromotion="isAddPromotion"
    @changeLoadingStatus="changeLoadingStatus"
  >
  </AddPromotion>

  <PromotionDetails
    v-show="isPromotionDetails == true"
    @hide="hidePromotionDetails"
    :selectedItemDetails="selectedItemDetails"
    @changeLoadingStatus="changeLoadingStatus"
    :isPromotionDetails="isPromotionDetails"
  >
  </PromotionDetails>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NDataTable,
  NSpace,
  NCard,
  NButton,
  NIcon,
  NModal,
  NInput,
  commonLight,
  NUpload,
  NForm,
  NDatePicker,
  NFormItem,
  FormItemRule,
  NRadio,
  NRadioGroup,
} from "naive-ui";
import Loading from "@/components/Loading.vue";
import AddPromotion from "@/views/promotionCode/AddPromotion.vue";
import PromotionDetails from "@/views/promotionCode/PromotionDetails.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import axios from "axios";
import type { UploadInst, FormInst } from "naive-ui";

const uploadPromotionCsv = getCallable("uploadPromotionCsv");
const getPromotions = getCallable("getPromotions");
const deletePromotion = getCallable("deletePromotion");
export default defineComponent({
  name: "PromotionCode",
  components: {
    NDataTable,
    NSpace,
    NInput,
    NCard,
    NButton,
    NIcon,
    NModal,
    NUpload,
    NForm,
    NDatePicker,
    NFormItem,
    Loading,
    NRadio,
    NRadioGroup,
    AddPromotion,
    PromotionDetails,
  },
  data() {
    return {
      isAddPromotion: false,
      selectedItem: undefined as any,
      selectedItemDetails: undefined as any,
      pagination: { pageSize: 50 },
      columns: [
        {
          title: "index",
          key: "index",
          sorter: "default",
        },
        {
          title: "프로모션 이름",
          key: "name",
          sorter: "default",
        },

        {
          title: "시작일",
          key: "parseStartDate",
          sorter: "default",
        },
        {
          title: "만료일",
          key: "parseExpDate",
          sorter: "default",
        },
        {
          title: "활성 여부",
          key: "activation",
          sorter: "default",
        },
        {
          title: "코드 보기",

          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.onViewCodes(row);
                },
                // bordered: false,
              },
              { default: () => "확인" }
            );
          },
        },
        {
          title: "수정",

          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.rowOnClick(row);
                },
                // bordered: false,
              },
              { default: () => "수정" }
            );
          },
        },
        {
          title: "삭제",

          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.onDelete(row);
                },
                // bordered: false,
              },
              { default: () => "삭제" }
            );
          },
        },
      ],
      baseList: [] as any,
      showList: [] as any,
      deleteItem: undefined as any,
      deleteConfirmType: "error",
      deleteConfirmMessage: "",
      isDeleteConfirm: false,
      isAlert: false,
      alertMessage: "",
      alertType: "",
      isPromotionDetails: false,
    };
  },
  setup() {},
  beforeUnmount() {},
  mounted() {
    this.getPromotionList();
  },
  methods: {
    confirmDelete(row: any) {
      this.deleteItem = row;
      this.deleteConfirmMessage = "삭제 하시겠습니까?";
      this.isDeleteConfirm = true;
    },
    onDeletePositiveClick() {
      this.deletePromotion();
      this.isDeleteConfirm = false;
    },
    onDeleteNegativeClick() {
      this.isDeleteConfirm = false;
      this.deleteItem = undefined;
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    deletePromotion() {
      if (this.deleteItem == undefined) {
        this.alertMessage = "삭제 실패";
        this.alertType = "error";
        this.isAlert = true;
        return;
      }
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        deletePromotion({
          id: this.deleteItem.SK,
        })
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "삭제 실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              this.alertMessage = "삭제 성공";
              this.alertType = "success";
              this.isAlert = true;
              this.getPromotionList();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.deleteItem = undefined;
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    changeLoadingStatus(status: any) {
      this.$emit("changeLoadingStatus", status);
    },
    showAddPromotion() {
      this.isAddPromotion = true;
    },

    hideAddPromotion() {
      this.selectedItem = undefined;
      this.isAddPromotion = false;
      this.getPromotionList();
    },
    hidePromotionDetails() {
      this.selectedItemDetails = undefined;
      this.isPromotionDetails = false;
    },
    selectItem(row: any) {
      this.selectedItem = row;
      this.isAddPromotion = true;
    },
    getPromotionList() {
      this.baseList = [];
      this.showList = [];
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        getPromotions({})
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              let i = 0;
              for (let item of result.data.data.Items) {
                if (item.startDate != undefined)
                  item.parseStartDate = common.getDate(item.startDate);
                if (item.expiryDate != undefined)
                  item.parseExpDate = common.getDate(item.expiryDate);
                if (item.isUse == undefined) {
                  item.activation = "비활성";
                } else {
                  if (item.isUse == 0) {
                    item.activation = "비활성";
                  } else {
                    item.activation = "활성";
                  }
                }

                item.rowOnClick = this.selectItem;
                item.onDelete = this.confirmDelete;
                item.onViewCodes = this.viewCodes;

                item.index = i++;
                this.baseList.push(item);
              }
              this.showList = this.baseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    viewCodes(row: any) {
      this.isPromotionDetails = true;
      this.selectedItemDetails = row;
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
