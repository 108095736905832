<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 300px; font-weight: bold">
              쿠폰 코드 > 코드 목록
            </div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>

      <NCard
        style="
          margin-top: 20px;
          margin-left: 100px;
          width: 100%;

          height: 100%;
        "
      >
        <p>Coupon Name: {{ promoId }}</p>

        <n-space style="justify-content: flex-end">
          <n-select
            style="font-size: 15px; width: 200px"
            v-model:value="downloadValue"
            :options="downloadValues"
          />
          <n-tooltip trigger="hover">
            <template #trigger>
              <NButton strong secondary circle type="primary" @click="download"
                ><template #icon>
                  <n-icon> <DownloadIcon /> </n-icon> </template
              ></NButton>
            </template>
            csv 다운로드</n-tooltip
          >
        </n-space>

        <div class="outer" style="height: 620px; margin-top: 50px">
          <n-data-table
            class="inner"
            style="width: 100%; height: 620px"
            ref="table"
            :columns="columns"
            :data="showList"
            :pagination="pagination"
            :max-height="520"
            striped
          />
        </div>
        <NButton
          dashed
          style="width: 250px; height: 30px; border: none"
          type="warning"
          v-on:click="hide"
        >
          뒤로
        </NButton>
      </NCard>
    </div>

    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
  </div>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NSpace,
  NCard,
  NButton,
  NIcon,
  NModal,
  NDataTable,
  commonLight,
  NSelect,
  DataTableInst,
  NTooltip,
} from "naive-ui";
import { Download as DownloadIcon } from "@vicons/ionicons5";
import Loading from "@/components/Loading.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import axios from "axios";
import type { UploadInst, FormInst } from "naive-ui";

const getCouponCodes = getCallable("getCouponCodes");

export default defineComponent({
  name: "CouponDetails",
  props: ["isCouponDetails", "selectedItemDetails"],
  components: {
    NSpace,
    NDataTable,
    NCard,
    NButton,
    NIcon,
    NModal,
    DownloadIcon,
    Loading,
    NSelect,
    NTooltip,
  },
  watch: {
    isCouponDetails: function (newIsDetails: any) {
      if (this.selectedItemDetails != null) {
        console.log(this.selectedItemDetails);
        this.sItem = this.selectedItemDetails;
        this.promoId = this.sItem.name ?? "";
        this.getCouponCodeList();
      }
    },
  },
  data() {
    return {
      tableRef: null as DataTableInst | null,
      promoId: "",
      isAdd: true as boolean,
      isAlert: false as boolean,
      alertType: "",
      alertMessage: "",
      isLoading: false,
      sItem: undefined as any,
      columns: [
        {
          title: "Coupon Code",
          key: "code",
          sorter: "default",
        },
        {
          title: "innaID",
          key: "innaId",
          sorter: "default",
          defaultFilterOptionValues: ["미사용", "사용완료"],
          filterOptions: [
            {
              label: "미사용",
              value: "미사용",
            },
            {
              label: "사용완료",
              value: "사용완료",
            },
          ],
          filter(value: any, row: any) {
            if (value === "미사용") {
              return row.innaId === undefined;
            }
            return row.innaId !== undefined;
          },
        },
      ],
      pagination: false,
      baseList: [] as any,
      showList: [] as any,
      downloadValues: [
        { label: "전체", value: 0 },
        { label: "미사용 코드", value: 1 },
        { label: "사용된 코드", value: 2 },
      ],
      downloadValue: 0,
    };
  },
  setup() {},
  beforeUnmount() {},
  mounted() {
    this.tableRef = this.$refs.table as DataTableInst;
  },
  methods: {
    onPositiveClick() {
      this.isAlert = false;
    },
    hide() {
      this.$emit("hide");
      this.downloadValue = 0;
    },
    getCouponCodeList() {
      this.baseList = [];
      this.showList = [];
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        let data = {
          id: this.sItem.SK,
        } as any;

        getCouponCodes(data)
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              let i = 0;
              for (let item of result.data.data.Items) {
                item.index = i++;
                this.baseList.push(item);
              }
              this.showList = this.baseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },

    download() {
      let fileName = this.sItem.SK;

      if (this.downloadValue == 0) {
        this.tableRef?.filter(null);
      } else if (this.downloadValue == 1) {
        this.tableRef?.filter({ innaId: ["미사용"] });
        fileName = fileName + "_미사용";
      } else if (this.downloadValue == 2) {
        this.tableRef?.filter({ innaId: ["사용"] });
        fileName = fileName + "_사용_완료";
      }

      this.tableRef?.downloadCsv({
        fileName: fileName,
        keepOriginalData: false,
      });

      this.tableRef?.filter(null);
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
