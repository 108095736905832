import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://kauth.kakao.com/oauth",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  },
});

export default {
  async getToken(data: any) {
    let result = undefined;
    await apiClient
      .post("/token", data)
      .then((response) => {
        result = response;
      })
      .catch((error) => {
        result = error.response;
      });
    return result;
  },
};
