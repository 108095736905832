import { firebaseAuth, Kakao, getCallable } from '@/config/env';
import { signInWithCustomToken, signOut } from 'firebase/auth';
import * as constant from '@/assets/constant';
const kakao = Kakao;


const joinAndLoginAdmin = getCallable('joinAndLoginAdmin');

export function getCurrentUser() {
  return firebaseAuth.currentUser;
}
export async function logout() {
  await kakao.Auth.logout()
    .then((response: any) => { })
    .catch(function (error: any) {
      console.log('Not logged in.');
    })
    .finally(async () => {
      await signOut(firebaseAuth);
    });
};

export async function kakaoGetUserInfo() {
  let userInfo = undefined;
  await kakao.API.request({
    url: '/v2/user/me',
  })
    .then((response: any) => {
      userInfo = response;
    })
    .catch((error: any) => {
      console.log(error);
    });
  return userInfo;
};

export async function join(id: any, nickname: any) {
  let result = await joinAndLoginAdmin({
    id: id,
    name: nickname,
    type: 'kakao',
  }) as any;
  if (result.data.error != undefined) {
    return {
      type: constant.login_type.ERROR,
    }
  } else {
    if (result.data.data.type == constant.login_type.LOGIN) {
      await signInWithCustomToken(firebaseAuth, result.data.data.token)
        .then((userCredential) => {
        })
        .catch((error) => {
          const errorMessage = error.message;
        });
      return {
        type: result.data.data.type,
      }
    } else if (result.data.data.type == constant.login_type.JOIN || result.data.data.type == constant.login_type.LOCK) {
      return {
        uid: result.data.data.uid,
        type: result.data.data.type,
      }
    }

  }

}