<template>
  <div v-show="isAddContent == false" class="main_div">
    <div style="height: auto">
      <n-space style="width: 1700px; min-width: 1700px; height: auto">
        <NCard style="margin-top: 20px; margin-left: 100px; width: 1000px; min-width: 1000px; height: 100%">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Section</div>
            <n-radio-group v-model:value="depth1Value" name="depth1Value" @change="selectSection">
              <n-radio v-for="d1 in depth1" :key="d1.key" :value="d1.key" :label="d1.key" style="font-size: 15px" />
            </n-radio-group>
            <span style="color: red"> * preview 선택시 Section 필터를 제외한 나머지 필터는 적용되지 않습니다.</span>
          </n-space>
          <div class="content_delimiter_line"></div>
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Age</div>
            <n-select style="font-size: 15px; width: 100px" v-model:value="minAgeValue" :options="minAgeValues" />
            ~
            <n-select style="font-size: 15px; width: 100px" v-model:value="maxAgeValue" :options="maxAgeValues" />
          </n-space>
          <div class="content_delimiter_line"></div>
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Category 1</div>
            <n-radio-group
              v-model:value="depth2Value"
              name="depth2Value"
              @change="selectCategory1"
              style="max-width: 750px; display: flex; flex-wrap: wrap"
            >
              <n-radio
                v-for="d2 in depth2"
                :key="d2.depth2"
                :value="d2.depth2"
                :label="d2.storyKor"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
          <div class="content_delimiter_line"></div>
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Category 2</div>
            <n-radio-group
              v-model:value="depth3Value"
              name="depth3Value"
              style="max-width: 750px; display: flex; flex-wrap: wrap"
            >
              <n-radio
                v-for="d3 in depth3"
                :key="d3.depth3"
                :value="d3.depth3"
                :label="d3.storyKor"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
          <div class="content_delimiter_line"></div>
          <n-space item-style="display: flex;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">주제</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="contentTitle"
              style="width: 700px; height: 30px; text-align: left"
            />
          </n-space>
          <div class="content_delimiter_line"></div>
          <n-space justify="center">
            <NButton style="width: 250px; height: 30px; border: none" v-on:click="search"> 검색 </NButton>
          </n-space>
        </NCard>
        <NCard
          :bordered="false"
          style="margin-top: 20px; margin-left: 30px; width: 550px; min-width: 550px; height: 100%"
        >
          <n-space style="height: 100%" justify="end" align="end">
            <NButton style="width: 200px; height: 30px; border: none" v-on:click="showAddContent"> 등록 </NButton>
          </n-space>
        </NCard>
      </n-space>
    </div>
    <div style="height: 620px; margin-top: 50px">
      <n-data-table
        class="inner"
        style="margin-left: 100px; width: 1600px; min-width: 1600px; height: 620px"
        ref="table"
        :columns="columns"
        :data="showList"
        :pagination="pagination"
        :max-height="520"
        striped
      />
    </div>
  </div>
  <AddContent
    v-show="isAddContent == true"
    @hide="hideAddContent"
    :isAddContent="isAddContent"
    :selectedItem="selectedItem"
    @changeLoadingStatus="changeLoadingStatus"
  >
  </AddContent>
  <NModal
    v-model:show="isAlert"
    :mask-closable="false"
    :content="alertMessage"
    :type="alertType"
    preset="dialog"
    title="Dialog"
    positive-text="확인"
    @positive-click="onPositiveClick"
  >
  </NModal>
</template>

<script lang="ts">
import { h, defineComponent, ref } from 'vue';
import { getCallable, firebaseStorage } from '@/config/env';
import {
  NDataTable,
  NSpace,
  NCheckbox,
  NCard,
  NSelect,
  NButton,
  NIcon,
  NModal,
  NRadio,
  NRadioGroup,
  NInput,
} from 'naive-ui';
import AddContent from '@/views/content/AddContent.vue';
import * as common from '@/assets/common/common';
import * as constant from '@/assets/constant';
import { LogOutOutline as LogOutIcon, Play as PlayIcon, Stop as StopIcon, Close as CloseIcon } from '@vicons/ionicons5';
const getContents = getCallable('getContents');
const getStorys = getCallable('getStorysForAdmin');

export default defineComponent({
  name: 'Content',
  emits: ['changeLoadingStatus'],
  components: {
    NDataTable,
    NCheckbox,
    NSpace,
    NInput,
    NCard,
    NSelect,
    NButton,
    AddContent,
    NIcon,
    NModal,
    NRadio,
    NRadioGroup,
  },
  data() {
    return {
      contentTitle: '',
      isAlert: false,
      alertMessage: '',
      alertType: '',
      informalSpeech: false,
      honorific: false,
      pagination: { pageSize: 100 },
      ageValues: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 40, 50, 60, 70],
      minAgeValues: [] as any,
      minAgeValue: 5,
      maxAgeValues: [] as any,
      maxAgeValue: 70,
      columns: [
        {
          title: 'index',
          key: 'index',
          sorter: 'default',
        },
        {
          title: 'Section',
          key: 'depth1',
          sorter: 'default',
        },
        {
          title: 'Age(Min)',
          key: 'minAge',
          sorter: 'default',
        },
        {
          title: 'Age(Max)',
          key: 'maxAge',
          sorter: 'default',
        },
        {
          title: 'Catetory',
          key: 'depth2',
          sorter: 'default',
        },
        {
          title: '주제',
          key: 'title',
          sorter: 'default',
        },
        {
          title: 'author',
          key: 'author',
          sorter: 'default',
        },
        {
          title: '최종 수정일',
          key: 'parseLastUpdatedDate',
          sorter: 'default',
        },
        {
          title: '듣기( 한글, 영어 )',
          render(row: any) {
            const plays = [
              h(
                NIcon,
                {
                  style: {
                    marginRight: '20px',
                  },
                  size: 'small',
                  onClick: () => {
                    row.playOnClick(row, constant.language.ko);
                  },
                  // bordered: false,
                },
                { default: () => h(row.rowIconKo) }
              ),
              h(
                NIcon,
                {
                  style: {
                    marginRight: '20px',
                  },
                  size: 'small',
                  onClick: () => {
                    row.playOnClick(row, constant.language.en);
                  },
                  // bordered: false,
                },
                { default: () => h(row.rowIconEn) }
              ),
            ];
            return plays;
          },
        },
        {
          title: '수정',
          sorter: 'default',
          render(row: any) {
            return h(
              NButton,
              {
                size: 'small',
                onClick: () => {
                  row.rowOnClick(row);
                },
                // bordered: false,
              },
              { default: () => '수정' }
            );
          },
        },
      ],
      baseList: [] as any,
      showList: [] as any,
      storyList: [] as any,
      depth1: [] as any,
      depth2: [] as any,
      depth3: [] as any,
      depth1Value: common.Section1 as any,
      depth2Value: undefined,
      depth3Value: undefined,
      isAddContent: false as boolean,
      selectedItem: undefined as any,
      audio: undefined as any,
    };
  },
  setup() {
    const tableRef = ref(null);
  },
  beforeUnmount() {
    this.pauseAudio();
  },
  mounted() {
    for (let age of this.ageValues) {
      this.minAgeValues.push({
        label: age + '세',
        value: age,
      });
      this.maxAgeValues.push({
        label: age + '세',
        value: age,
      });
    }
    this.fetchData(false);
  },
  methods: {
    async fetchData(isOnlyContentList: any) {
      this.$emit('changeLoadingStatus', true);
      try {
        if (isOnlyContentList) {
          await Promise.all([this.getContentList()]);
        } else {
          await Promise.all([this.getContentList(), this.getStorys()]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit('changeLoadingStatus', false);
      }
    },
    pauseAudio() {
      if (this.audio != undefined) this.audio.pause();
    },
    selectItem(row: any) {
      this.selectedItem = row;
      this.isAddContent = true;
      this.pauseAudio();
    },
    selectSection() {
      this.depth2 = [];
      let isFirst = true;
      for (let item of this.storyList) {
        if (item.depth1 == this.depth1Value && item.depth3 == undefined) {
          if (isFirst) {
            isFirst = false;
            this.depth2Value = item.depth2;
          }
          this.depth2.push(item);
        }
      }
      this.selectCategory1();
    },
    selectCategory1() {
      this.depth3 = [];
      let isFirst = true;
      for (let item of this.storyList) {
        if (item.depth1 == this.depth1Value && item.depth2 == this.depth2Value && item.depth3 != undefined) {
          if (isFirst) {
            isFirst = false;
            this.depth3Value = item.depth3;
          }
          this.depth3.push(item);
        }
      }
    },
    changeLoadingStatus(status: any) {
      this.$emit('changeLoadingStatus', status);
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    showAddContent() {
      this.isAddContent = true;
    },
    hideAddContent() {
      this.selectedItem = undefined;
      this.isAddContent = false;
      this.fetchData(true);
    },
    async search() {
      this.$emit('changeLoadingStatus', true);

      common.asyncFunction(async () => {
        this.showList = [];
        let i = 0;
        console.log('baseList : ' + this.baseList.length);
        for (let baseItem of this.baseList) {
          if (this.depth1Value == common.preview) {
            if (baseItem.SK != common.previewContent) continue;
          } else if (this.depth1Value == common.giftPreview) {
            if (baseItem.SK != common.giftPreviewContent) continue;
          } else {
            if (this.depth1Value != baseItem.depth1) continue;
            if (this.depth2Value != undefined && this.depth2Value != baseItem.depth2) continue;
            if (this.depth3Value != undefined && this.depth3Value != baseItem.depth3) continue;
            if (!(this.minAgeValue <= baseItem.minAge && this.maxAgeValue >= baseItem.maxAge)) continue;
            if (
              this.contentTitle != undefined &&
              this.contentTitle != '' &&
              baseItem.title.indexOf(this.contentTitle) == -1
            )
              continue;
          }

          baseItem.index = i++;
          this.showList.push(baseItem);
        }
        this.$emit('changeLoadingStatus', false);
      });
    },
    getContentList(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.baseList = [];
        this.showList = [];
        //this.$emit("changeLoadingStatus", true);
        common.asyncFunction(async () => {
          getContents({})
            .then(async (result: any) => {
              if (result.data.error) {
                reject(result.data.error);
              } else {
                console.log(result.data.data.Items);
                let i = 0;
                for (let item of result.data.data.Items) {
                  item.minAge = item.minAge ? item.minAge : this.ageValues[0];
                  item.maxAge = item.maxAge ? item.maxAge : this.ageValues[this.ageValues.length - 1];
                  item.rowOnClick = this.selectItem;
                  item.playOnClick = this.playStopFile;
                  if (item.contentFileUrlEn == undefined || item.contentFileUrlEn == '') item.rowIconEn = CloseIcon;
                  else item.rowIconEn = PlayIcon;

                  if (item.contentFileUrlKo == undefined || item.contentFileUrlKo == '') item.rowIconKo = CloseIcon;
                  else item.rowIconKo = PlayIcon;

                  if (item.lastUpdatedDate != undefined)
                    item.parseLastUpdatedDate = common.getDate(item.lastUpdatedDate);

                  item.index = i++;
                  this.baseList.push(item);
                }
                this.showList = this.baseList;
                resolve();
              }
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            })
            .finally(() => {
              //this.$emit("changeLoadingStatus", false);
            });
        });
      });
    },
    playStopFile(row: any, language: any) {
      this.$emit('changeLoadingStatus', true);
      common.asyncFunction(async () => {
        this.pauseAudio();

        let currentIcon = PlayIcon;
        let iconKey = 'rowIconKo';
        let urlKey = 'contentFileUrlKo';
        if (language == constant.language.ko) {
          iconKey = 'rowIconKo';
          urlKey = 'contentFileUrlKo';
        } else if (language == constant.language.en) {
          iconKey = 'rowIconEn';
          urlKey = 'contentFileUrlEn';
        }

        for (let item of this.showList) {
          if (item.SK == row.SK) {
            currentIcon = row[iconKey];
          }
          if (item.rowIconKo.name != CloseIcon.name) item.rowIconKo = PlayIcon;
          if (item.rowIconEn.name != CloseIcon.name) item.rowIconEn = PlayIcon;
        }

        if (currentIcon.name == CloseIcon.name) {
          this.$emit('changeLoadingStatus', false);
          return;
        }

        if (currentIcon.name == PlayIcon.name) {
          let url = (await common.getFileUrl(row[urlKey])) as any;
          if (url.success) {
            this.audio = new Audio(url.data);
            this.audio
              .play()
              .then(() => {
                row[iconKey] = StopIcon;
                this.$emit('changeLoadingStatus', false);
              })
              .catch((error: any) => {
                row[iconKey] = PlayIcon;
                this.$emit('changeLoadingStatus', false);
                this.alertMessage = error;
                this.alertType = 'error';
                this.isAlert = true;
                return;
              });
            this.audio.addEventListener('ended', function () {
              row[iconKey] = PlayIcon;
            });
          } else {
            this.$emit('changeLoadingStatus', false);
            this.alertMessage = url.message._baseMessage;
            this.alertType = 'error';
            this.isAlert = true;
            this.$emit('changeLoadingStatus', false);
          }
        } else {
          row[iconKey] = PlayIcon;
          this.$emit('changeLoadingStatus', false);
        }
      });
    },
    getStorys(): Promise<void> {
      //this.$emit("changeLoadingStatus", true);
      return new Promise((resolve, reject) => {
        common.asyncFunction(async () => {
          getStorys({})
            .then(async (result: any) => {
              this.storyList = result.data.data.Items;
              let depth1Set = new Set();
              let depth2Set = new Set();
              for (let item of result.data.data.Items) {
                if (item.depth1 != undefined) depth1Set.add(item.depth1);
                if (item.depth2 != undefined) depth2Set.add(item.depth2);
              }
              let depth1List = Array.from(depth1Set).sort();
              for (let d1 of depth1List) {
                this.depth1.push({
                  key: d1,
                  value: false,
                });
              }
              this.depth1.push({
                key: common.preview,
                value: false,
              });
              this.depth1.push({
                key: common.giftPreview,
                value: false,
              });
              this.selectSection();
              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            })
            .finally(() => {
              // this.$emit("changeLoadingStatus", false);
            });
        });
      });
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
