<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">회원 관리</div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>
      <div style="display: flex">
        <n-space>
          <NCard
            style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px"
          >
            <n-space item-style="display: flex;">
              <div style="font-size: 20px; width: 150px; font-weight: bold">INNA_ID</div>
              <NInput
                type="text"
                placeholder=""
                v-model:value="searchInnaId"
                style="width: 300px; height: 30px; text-align: left"
              />
              <NButton
                style="width: 100px; height: 30px; margin-left: 50px; border: none"
                v-on:click="search"
              >
                검색
              </NButton>
            </n-space>
          </NCard>
        </n-space>
        <n-space item-style="display: flex;">
          <NCard style="margin-left: 80px; border: 0px">
            <div style="border: 1px solid rgb(204, 204, 204)">
              <div
                style="
                  font-size: 20px;
                  width: 150px;
                  background-color: rgb(230, 230, 230);
                  color: black;
                  font-weight: bold;
                "
              >
                총 회원 수
              </div>
              <div
                style="
                  font-size: 30px;
                  width: 150px;
                  font-weight: bold;
                  padding: 10px 0 10px 0;
                "
              >
                {{ totalMember }}
              </div>
            </div>
          </NCard>
          <NCard style="border: 0px">
            <div style="border: 1px solid rgb(204, 204, 204)">
              <div
                style="
                  font-size: 20px;
                  width: 150px;
                  background-color: rgb(230, 230, 230);
                  color: black;
                  font-weight: bold;
                "
              >
                구독자 수
              </div>
              <div
                style="
                  font-size: 30px;
                  width: 150px;
                  font-weight: bold;
                  padding: 10px 0 10px 0;
                "
              >
                {{ subscriber }}
              </div>
              <div>Android : {{ androidActive }}</div>
              <div>IOS : {{ iosActive }}</div>
            </div>
          </NCard>
        </n-space>
      </div>
    </div>
    <div class="outer" style="height: 620px; margin-top: 50px">
      <n-data-table
        class="inner"
        style="margin-left: 100px; width: 1600px; min-width: 1600px; height: 620px"
        ref="table"
        :columns="columns"
        :data="showList"
        :pagination="pagination"
        :max-height="520"
        striped
      />
    </div>
  </div>
  <NModal
    v-model:show="isAlert"
    :mask-closable="false"
    title="구독 정보"
    preset="dialog"
    positive-text="확인"
    @positive-click="onPositiveClick"
  >
    <template v-if="alertType === 'error'">
      <n-card :bordered="false" role="dialog" aria-modal="true">
        {{ alertMessage }}
      </n-card>
    </template>
    <template v-else>
      <n-card :bordered="false" role="dialog" aria-modal="true">
        <div>
          <p>구독 타입: {{ subscribeType }}</p>
          <p>기기 타입: {{ deviceType }}</p>
        </div>
      </n-card>
    </template>
  </NModal>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NDataTable,
  NSpace,
  NCard,
  NButton,
  NIcon,
  NModal,
  NInput,
  commonLight,
} from "naive-ui";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import {
  LogOutOutline as LogOutIcon,
  Play as PlayIcon,
  Stop as StopIcon,
  Close as CloseIcon,
} from "@vicons/ionicons5";

const getMembers = getCallable("getMembers");
const getProductsByMember = getCallable("getProductsByMember");
const getOrdersByStatus = getCallable("getOrdersByStatus");

export default defineComponent({
  name: "Member",
  emits: ["changeLoadingStatus"],
  components: {
    NDataTable,
    NSpace,
    NInput,
    NCard,
    NButton,
    NIcon,
    NModal,
  },
  data() {
    return {
      searchInnaId: "",
      isAlert: false,
      pagination: { pageSize: 50 },
      columns: [
        {
          title: "index",
          key: "index",
          sorter: "default",
        },
        {
          title: "INNA_ID",
          key: "innaId",
          sorter: "default",
        },
        {
          title: "이름",
          key: "name",
          sorter: "default",
        },
        {
          title: "연령",
          key: "age",
          sorter: "default",
        },
        {
          title: "구독",
          key: "isSubscribe",
          sorter: {
            compare: (a: any, b: any) => a.isSubscribe.localeCompare(b.isSubscribe),
            multiple: 2,
          },
        },
        {
          title: "구독 타입",
          sorter: "default",
          render(row: any) {
            return row.subscribe == 1
              ? h(
                  NButton,
                  {
                    size: "small",
                    onClick: () => {
                      row.onShowDetails(row);
                    },
                    // bordered: false,
                  },
                  { default: () => "확인" }
                )
              : null;
          },
        },
        {
          title: "가입일",
          key: "parseCreatedDate",
          sorter: {
            compare: (a: any, b: any) => {
              if (!a.createdDate && !b.createdDate) return 0;
              if (!a.createdDate) return -1;
              if (!b.createdDate) return 1;

              return a.createdDate.localeCompare(b.createdDate);
            },

            multiple: 1,
          },
        },
      ],
      baseList: [] as any,
      showList: [] as any,
      totalMember: 0,
      subscriber: 0,
      isShowSubscribeType: false,
      selectedUid: "",
      alertMessage: "",
      subscribeType: "",
      deviceType: "",
      alertType: "",
      androidActive: 0,
      iosActive: 0,
    };
  },
  setup() {
    const tableRef = ref(null);
  },
  beforeUnmount() {},
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.$emit("changeLoadingStatus", true);
      try {
        await Promise.all([this.getMemberList(), this.getOrdersByStatus()]);
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("changeLoadingStatus", false);
      }
    },
    async search() {
      this.$emit("changeLoadingStatus", true);

      common.asyncFunction(async () => {
        this.showList = [];
        for (let baseItem of this.baseList) {
          if (this.searchInnaId == "") {
          } else if (
            baseItem.innaId == undefined ||
            (this.searchInnaId != undefined &&
              this.searchInnaId != "" &&
              baseItem.innaId.indexOf(this.searchInnaId) == -1)
          )
            continue;

          this.showList.push(baseItem);
        }
        this.$emit("changeLoadingStatus", false);
      });
    },
    getMemberList(): Promise<void> {
      return new Promise((resolve, reject) => {
        this.baseList = [];
        this.showList = [];
        let currentYear = new Date().getFullYear();
        // this.$emit("changeLoadingStatus", true);
        common.asyncFunction(async () => {
          getMembers({})
            .then(async (result: any) => {
              if (result.data.error) {
                reject(result.data.error);
              } else {
                console.log(result.data.data.Items);
                let i = 0;
                result.data.data.Items.sort(function (a: any, b: any) {
                  if (a.createdDate < b.createdDate) return 1;
                  if (a.createdDate > b.createdDate) return -1;
                  if (a.createdDate == b.createdDate) return 0;
                });
                for (let item of result.data.data.Items) {
                  item.onShowDetails = this.onShowSubscribeType;
                  item.index = i++;
                  item.parseCreatedDate = common.getDate(item.createdDate);
                  if (item.yearOfBirth != undefined && item.yearOfBirth != "")
                    item.age = currentYear - Number(item.yearOfBirth);
                  item.isSubscribe = item.subscribe == 1 ? "O" : "X";
                  if (item.subscribe == 1) {
                    this.subscriber += 1;
                  }
                  this.baseList.push(item);
                }
                this.showList = this.baseList;
                this.totalMember = this.baseList.length;
                resolve();
              }
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            })
            .finally(() => {
              // this.$emit("changeLoadingStatus", false);
            });
        });
      });
    },
    getOrdersByStatus(): Promise<void> {
      // this.$emit("changeLoadingStatus", true);
      return new Promise((resolve, reject) => {
        common.asyncFunction(async () => {
          let data = {
            status: "ACTIVE",
          } as any;
          getOrdersByStatus(data)
            .then(async (result: any) => {
              if (result.data.error) {
                reject(result.data.error);
              } else {
                console.log(result.data.data.Items);
                let androidActive = 0;
                let iosActive = 0;

                for (let item of result.data.data.Items) {
                  if (item.iapSource == constant.iap_source.ANDROID) {
                    androidActive += 1;
                  } else {
                    iosActive += 1;
                  }
                }

                this.androidActive = androidActive;
                this.iosActive = iosActive;
              }
              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            })
            .finally(() => {
              // this.$emit("changeLoadingStatus", false);
            });
        });
      });
    },

    onShowSubscribeType(row: any) {
      this.isShowSubscribeType = true;
      if (row.uid != undefined) {
        this.$emit("changeLoadingStatus", true);
        common.asyncFunction(async () => {
          getProductsByMember({ uid: row.uid })
            .then((result: any) => {
              if (result.data.error) {
                this.alertMessage = "실패";
                this.alertType = "error";
                this.isAlert = true;
              } else {
                this.alertType = "success";
                var data = result.data.data.Items;

                if (data.length > 0) {
                  this.subscribeType = this.getSubscribeType(data[0].productId);
                  this.deviceType = this.getDeviceType(data[0].iapSource);
                }

                this.isAlert = true;
              }
            })
            .catch((error) => {
              console.log(error);
              this.alertMessage = "실패";
              this.alertType = "error";
              this.isAlert = true;
            })
            .finally(() => {
              this.$emit("changeLoadingStatus", false);
            });
        });
      } else {
      }
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    getSubscribeType(productId: String) {
      if (productId == constant.subscribe_type.MONTHLY) {
        return "월간 구독";
      } else if (productId == constant.subscribe_type.YEARLY) {
        return "연간 구독";
      } else {
        return "구독 정보 없음";
      }
    },
    getDeviceType(iapSource: String) {
      if (iapSource == constant.iap_source.ANDROID) {
        return "Android";
      } else if (iapSource == constant.iap_source.IOS) {
        return "IOS";
      } else {
        return "기기정보 없음";
      }
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
