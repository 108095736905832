<template>
  <div class="main_div" v-show="isAddAnswer == false">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">의견 접수</div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>
    </div>
    <div class="outer" style="height: 620px; margin-top: 50px">
      <n-data-table
        class="inner"
        style="margin-left: 100px; width: 1600px; min-width: 1600px; height: 620px"
        ref="table"
        :columns="columns"
        :data="showList"
        :pagination="pagination"
        :max-height="520"
        striped
      />
    </div>
  </div>
  <AddAnswer
    v-show="isAddAnswer == true"
    @hide="hideAddAnswer"
    @answered="getFeedbackList"
    :isAddAnswer="isAddAnswer"
    :selectedItem="selectedItem"
    @changeLoadingStatus="changeLoadingStatus"
  >
  </AddAnswer>
  <NModal
    v-model:show="isAlert"
    :mask-closable="false"
    :content="alertMessage"
    :type="alertType"
    preset="dialog"
    title="Dialog"
    positive-text="확인"
    @positive-click="onPositiveClick"
  >
  </NModal>
  <NModal v-model:show="isShowContent">
    <n-card
      style="width: 600px"
      title="내용"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      {{ contentMessage }}
    </n-card>
  </NModal>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import {
  NDataTable,
  NSpace,
  NCard,
  NButton,
  NIcon,
  NModal,
  NInput,
  commonLight,
} from "naive-ui";
import AddAnswer from "@/views/feedback/AddAnswer.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import {
  LogOutOutline as LogOutIcon,
  Play as PlayIcon,
  Stop as StopIcon,
  Close as CloseIcon,
} from "@vicons/ionicons5";

const getFeedbacks = getCallable("getFeedbacks");
const modifyFeedback = getCallable("modifyFeedback");
export default defineComponent({
  name: "Feedback",
  emits: ["changeLoadingStatus"],
  components: {
    NDataTable,
    NSpace,
    NInput,
    NCard,
    NButton,
    NIcon,
    NModal,
    AddAnswer,
  },
  data() {
    return {
      contentTitle: "",
      isAlert: false,
      alertMessage: "",
      alertType: "",
      isShowContent: false,
      contentMessage: "",
      pagination: { pageSize: 50 },

      columns: [
        {
          title: "index",
          key: "index",
          sorter: "default",
        },
        {
          title: "접수 일시",
          key: "parseCreatedDate",
          sorter: "default",
        },
        {
          title: "구분",
          key: "parseType",
          sorter: "default",
        },
        {
          title: "내용",
          key: "content",
          sorter: "default",
          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.onShowContent(row);
                },
                // bordered: false,
              },
              { default: () => row.parseContent }
            );
          },
        },
        {
          title: "email",
          key: "email",
          sorter: "default",
        },
        {
          title: "INNA_ID",
          key: "author",
          sorter: "default",
        },
        {
          title: "답변 완료 여부",
          key: "statusText",
          sorter: "default",
          // render(row: any) {
          //   return h(
          //     NButton,
          //     {
          //       size: 'small',
          //       onClick: () => {
          //         row.onChangeFeedbackStatus(row);
          //       },
          //       // bordered: false,
          //     },
          //     { default: () => row.statusText }
          //   );
          // },
        },
        {
          title: "답변등록",
          key: "addFeedback",
          sorter: "default",
          render(row: any) {
            return h(
              NButton,
              {
                size: "small",
                onClick: () => {
                  row.rowOnClick(row);
                },
                // bordered: false,
              },
              { default: () => "수정" }
            );
          },
        },
      ],
      baseList: [] as any,
      showList: [] as any,
      isAddAnswer: false as boolean,
      selectedItem: undefined as any,
    };
  },
  setup() {
    const tableRef = ref(null);
  },
  beforeUnmount() {},
  mounted() {
    this.getFeedbackList();
  },
  methods: {
    onContentModalPositiveClick() {
      this.isShowContent = false;
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    onShowContent(row: any) {
      this.isShowContent = true;
      this.contentMessage = row.content;
    },
    changeLoadingStatus(status: any) {
      this.$emit("changeLoadingStatus", status);
    },
    onChangeFeedbackStatus(row: any) {
      this.$emit("changeLoadingStatus", true);
      let changeStatus = row.status == "10" ? "20" : "10";
      common.asyncFunction(async () => {
        modifyFeedback({
          id: row.SK,
          status: changeStatus,
        })
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "수정 실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              row.status = changeStatus;
              row.statusText = common.getStatusText(changeStatus);
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    getFeedbackList() {
      this.baseList = [];
      this.showList = [];
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        getFeedbacks({})
          .then(async (result: any) => {
            if (result.data.error) {
            } else {
              console.log(result.data.data.Items);
              let i = 0;
              for (let item of result.data.data.Items) {
                item.index = i++;
                item.parseCreatedDate = common.getDate(item.createdDate);
                item.parseType = common.getFeedbackType(item.type);
                item.statusText = common.getStatusText(item.status);
                item.parseContent = common.parseContent(item.content);
                item.onShowContent = this.onShowContent;
                item.onChangeFeedbackStatus = this.onChangeFeedbackStatus;
                item.rowOnClick = this.addAnswer;
                this.baseList.push(item);
              }
              this.showList = this.baseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    addAnswer(row: any) {
      this.selectedItem = row;
      this.isAddAnswer = true;
    },
    hideAddAnswer() {
      this.selectedItem = undefined;
      this.isAddAnswer = false;
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
