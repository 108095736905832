<template>
  <div class="top-bar">
    <div style="width: 10%">
      <div style="color: white; font-size: 20px; font-weight: bold">{{ $t('title') }}</div>
    </div>
    <div style="width: 80%"></div>
    <div style="width: 10%">
      <div style="color: white; font-size: 15px; font-weight: bold; width: 100px; float: left; margin-top: 5px">
        <!-- {{ nickname }} 님 -->
      </div>
      <NButton quaternary style="color: white; float: left" v-on:click="logout">
        <template #icon>
          <NIcon size="25"><LogOutIcon /></NIcon>
        </template>
      </NButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance } from 'vue';
import * as apiService from '@/service/apiService';
import * as common from '@/assets/common/common';
import * as auth from '@/assets/common/auth';
import { NButton, NIcon } from 'naive-ui';
import { LogOutOutline as LogOutIcon } from '@vicons/ionicons5';

export default defineComponent({
  components: {
    NButton,
    NIcon,
    LogOutIcon,
  },
  async mounted() {},
  methods: {
    async logout() {
      await auth.logout();
      (this as any).$router.push('/');
    },
  },
  data() {
    return {
      kakaoUserInfo: undefined as any,
    };
  },
});
</script>

<style scoped>
.top-bar {
  width: 100%;
  height: 75px;
  display: flex;
  background-color: rgb(23, 53, 80);
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 0 30px;
  box-sizing: border-box;
}
</style>
