<template>
  <div class="side-bar">
    <NMenu
      :on-update-value="changeSelectedHomeView"
      :options="options"
      style="width: 140px"
      default-value="DashBoard"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, h } from "vue";
import * as apiService from "@/service/apiService";
import { firebaseAuth, getCallable, Kakao } from "@/config/env";
import { signInWithCustomToken, signOut } from "firebase/auth";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import { NMenu } from "naive-ui";
import { LogOutOutline as LogOutIcon } from "@vicons/ionicons5";

const kakao = Kakao;

export default defineComponent({
  components: {
    NMenu,
  },
  async mounted() {},
  watch: {
    extselectedHomeViewConstant: function (newVal) {
      this.selectedHomeViewConstant = newVal;
    },
  },
  methods: {
    changeSelectedHomeView(homeViewConstant: any) {
      if (this.selectedHomeViewConstant != homeViewConstant) {
        this.selectedHomeViewConstant = homeViewConstant;
        this.$emit("changeSelectedHomeView", homeViewConstant);
      }
    },
    logout() {
      kakao.Auth.logout()
        .then((response: any) => {
          signOut(firebaseAuth);
        })
        .catch(function (error: any) {
          console.log("Not logged in.");
        })
        .finally(() => {
          (this as any).$router.push("/");
        });
    },
    async kakaoGetUserInfo() {
      await kakao.API.request({
        url: "/v2/user/me",
      })
        .then((response: any) => {
          console.log(response);
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
  },
  props: ["extselectedHomeViewConstant"],
  data() {
    return {
      selectedHomeViewConstant: constant.homeView.DashBoard,
      options: [
        {
          label: "DashBorad",
          key: constant.homeView.DashBoard,
        },
        {
          label: "회원 관리",
          key: constant.homeView.Member,
        },
        {
          label: "콘텐츠 관리",
          key: constant.homeView.Content,
        },
        {
          label: "카테고리 관리",
          key: constant.homeView.Category,
        },
        {
          label: "공지 관리",
          key: constant.homeView.Notice,
        },
        {
          label: "의견 접수",
          key: constant.homeView.Feedback,
        },
        {
          label: "일레븐랩스",
          key: constant.homeView.ElevenLabs,
        },
        {
          label: "프로모션 코드",
          key: constant.homeView.PromotionCode,
        },
        {
          label: "쿠폰 관리",
          key: constant.homeView.Coupon,
        },
        {
          label: "발행처 관리",
          key: constant.homeView.Publisher,
        },
        {
          label: "관리자",
          key: constant.homeView.Admin,
        },
      ] as any,
    };
  },
});
</script>

<style scoped>
.side-bar {
  width: 150px;
  height: calc(100vh - 75px);
  display: flex;
  float: left;
  background-color: rgb(32, 75, 117);
}

.n-menu.n-menu--vertical {
  --n-item-text-color: white !important;
  --n-item-text-color-active: white !important;
  --n-item-text-color-active-hover: white !important;
  --n-item-color-hover: rgba(247, 244, 244, 0.379) !important;
  --n-item-color-active: rgba(247, 244, 244, 0.379) !important;
  --n-item-color-active-hover: rgba(247, 244, 244, 0.379) !important;
  --n-item-color-active-collapsed: rgba(247, 244, 244, 0.379) !important;
}
</style>
