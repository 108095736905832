<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space style="width: 1800px; min-width: 1800px; height: auto">
        <NCard
          style="
            margin-top: 20px;
            margin-left: 100px;
            width: 1800px;
            min-width: 1800px;
            height: 100%;
          "
        >
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Section</div>
            <n-radio-group
              v-model:value="depth1Value"
              name="depth1Value"
              @change="selectSection"
              v-bind:disabled="isAdd == false"
            >
              <n-radio
                v-for="d1 in depth1"
                :key="d1.key"
                :value="d1.key"
                :label="d1.key"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Age</div>
            <n-select
              style="font-size: 15px; width: 100px"
              v-model:value="minAgeValue"
              :options="minAgeValues"
            />
            ~
            <n-select
              style="font-size: 15px; width: 100px"
              v-model:value="maxAgeValue"
              :options="maxAgeValues"
            />
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Category 1</div>
            <n-radio-group
              v-model:value="depth2Value"
              name="depth2Value"
              @change="selectCategory1"
              v-bind:disabled="isAdd == false"
            >
              <n-radio
                v-for="d2 in depth2"
                :key="d2.depth2"
                :value="d2.depth2"
                :label="d2.storyKor"
                style="font-size: 15px"
              />
            </n-radio-group>
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">Category 2</div>
            <n-radio-group v-model:value="depth3Value" name="depth3Value">
              <n-radio
                v-for="d3 in depth3"
                :key="d3.depth3"
                :value="d3.depth3"
                :label="d3.storyKor"
                style="font-size: 15px"
                v-bind:disabled="isAdd == false"
              />
            </n-radio-group>
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">주제</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="contentTitle"
              style="width: 1000px; height: 30px; text-align: left"
            />
          </n-space>
          <div class="add_content_delimiter_line"></div>

          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              내용 기반<br />파일 생성 여부
            </div>
            <n-radio-group
              v-model:value="contentTypeValue"
              name="contentTypeValue"
              @change="selectContentType"
              v-bind:disabled="
                depth1Value == section1 ||
                depth1Value == preview ||
                depth1Value == giftPreview
              "
            >
              <n-radio
                v-for="ct in contentType"
                :key="ct"
                :value="ct"
                :label="ct"
                style="font-size: 15px"
              />
            </n-radio-group>
            <span style="color: red"> * O 선택시 파일 업로드 불가 </span>
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <div
            style="
              font-size: 16px;
              display: flex;
              justify-content: start;
              margin-bottom: 10px;
            "
          >
            * 음성 타입 미리 듣기 : &nbsp

            <a
              href="https://cloud.google.com/text-to-speech/docs/voices?hl=ko"
              target="_blank"
            >
              바로 가기</a
            >
          </div>
          <n-space item-style="display: flex; height:200px;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">한글 내용</div>
            <NInput
              type="textarea"
              placeholder=""
              v-model:value="contentKo"
              style="width: 1000px; height: 200px; text-align: left"
            />
          </n-space>
          <n-space item-style="display: flex; margin-top: 50px;margin-bottom: 50px;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              한국어 음성 타입
            </div>
            <n-select
              style="font-size: 15px; width: 300px"
              v-model:value="voiceTypeKo"
              :options="voiceTypeKoValues"
              :disabled="isDisableVoiceType"
          /></n-space>

          <n-space item-style="display: flex; ;margin-bottom: 50px;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              한국어 음성 Speed
            </div>
            <NInputNumber
              placeholder=""
              v-model:value="contentKoSpeakingRate"
              style="width: 100px; height: 30px; text-align: left"
              :step="0.1"
              :min="0.25"
              :max="4"
              :disabled="isDisableVoiceType"
            />
            <span style="color: red"> * 0.25 ~ 4 숫자만 입력 가능 </span>
          </n-space>

          <n-space item-style="display: flex; ;margin-bottom: 50px;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              한국어 음성 Pitch
            </div>
            <NInputNumber
              placeholder=""
              v-model:value="contentKoPitch"
              style="width: 100px; height: 30px; text-align: left"
              :step="0.1"
              :min="-20"
              :max="20"
              :disabled="isDisableVoiceType"
            />
            <span style="color: red"> * -20 ~ 20 숫자만 입력 가능 </span>
          </n-space>

          <n-space item-style="display: flex; height:200px; margin-top: 15px;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">영어 내용</div>
            <NInput
              type="textarea"
              placeholder=""
              v-model:value="contentEn"
              style="width: 1000px; height: 200px; text-align: left"
            />
          </n-space>
          <n-space item-style="display: flex; margin-top: 50px;margin-bottom: 50px;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              영어 음성 타입
            </div>
            <n-select
              style="font-size: 15px; width: 300px"
              v-model:value="voiceTypeEn"
              :options="voiceTypeEnValues"
              :disabled="isDisableVoiceType"
          /></n-space>

          <n-space item-style="display: flex; ;margin-bottom: 50px;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              영어 음성 Speed
            </div>
            <NInputNumber
              placeholder=""
              v-model:value="contentEnSpeakingRate"
              style="width: 100px; height: 30px; text-align: left"
              :step="0.1"
              :min="0.25"
              :max="4"
              :disabled="isDisableVoiceType"
            />
            <span style="color: red"> * 0.25 ~ 4 숫자만 입력 가능 </span>
          </n-space>

          <n-space item-style="display: flex; ;margin-bottom: 50px;" align="center">
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              영어 음성 Pitch
            </div>
            <NInputNumber
              placeholder=""
              v-model:value="contentEnPitch"
              style="width: 100px; height: 30px; text-align: left"
              :step="0.1"
              :min="-20"
              :max="20"
              :disabled="isDisableVoiceType"
            />
            <span style="color: red"> * -20 ~ 20 숫자만 입력 가능 </span>
          </n-space>

          <n-space
            align="start"
            item-style="display: flex; height:200px;  margin-top: 15px;"
            v-bind:disabled="contentTypeValue == 'O'"
          >
            <div style="font-size: 20px; width: 150px; font-weight: bold">한글 파일</div>
            <n-upload
              ref="uploadKo"
              style="width: 300px"
              :default-upload="false"
              max="1"
              @change="contentKoSeclect"
              v-bind:disabled="contentTypeValue == 'O' || !isShowUploadFile"
            >
              <n-button>Select File</n-button>
            </n-upload>
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              현재 저장 파일
            </div>
            <div style="font-size: 20px">
              {{ contentFileUrlKo }}
            </div>
          </n-space>
          <n-space align="start" item-style="display: flex; height:200px; ">
            <div style="font-size: 20px; width: 150px; font-weight: bold">영어 파일</div>
            <n-upload
              ref="uploadEn"
              style="width: 300px"
              :default-upload="false"
              max="1"
              @change="contentEnSeclect"
              v-bind:disabled="contentTypeValue == 'O' || !isShowUploadFile"
            >
              <n-button>Select File</n-button>
            </n-upload>
            <div style="font-size: 20px; width: 150px; font-weight: bold">
              현재 저장 파일
            </div>
            <div style="font-size: 20px">
              {{ contentFileUrlEn }}
            </div>
          </n-space>
          <n-space align="start">
            <div
              v-show="
                !isAdd &&
                sItem != undefined &&
                sItem.isCreate == false &&
                contentTypeValue == 'X'
              "
              style="font-size: 20px; width: 150px; font-weight: bold"
            >
              파일 재등록
            </div>
            <NButton
              v-show="
                !isAdd &&
                sItem != undefined &&
                sItem.isCreate == false &&
                contentTypeValue == 'X'
              "
              style="width: 100px; height: 30px; border: none"
              v-on:click="fileReUpload"
            >
              {{ resetUploadFileButtonText }}
            </NButton>
          </n-space>

          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">작성자</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="author"
              style="width: 1000px; height: 30px; text-align: left"
              disabled
            />
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space style="margin-top: 50px" justify="center">
            <NButton
              dashed
              style="width: 250px; height: 30px; border: none"
              type="warning"
              v-on:click="hide"
            >
              취소
            </NButton>

            <NButton
              dashed
              style="width: 250px; height: 30px; border: none"
              type="success"
              v-on:click="checkAddContent"
            >
              {{ submitButtonText }}
            </NButton>
            <NButton
              v-show="!isAdd && depth1Value != preview && depth1Value != giftPreview"
              dashed
              style="width: 250px; height: 30px; border: none"
              type="error"
              v-on:click="confirmDelete"
            >
              삭제
            </NButton>
          </n-space>
        </NCard>
      </n-space>
    </div>
    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
    <NModal
      v-model:show="isPreviewConfirm"
      :mask-closable="false"
      :content="previewConfiremMessage"
      :type="defalut"
      preset="dialog"
      negative-text="취소"
      positive-text="확인"
      @positive-click="onPreviewPositiveClick"
      @negative-click="onPreviewNegativeClick"
    >
    </NModal>
    <NModal
      v-model:show="isGiftPreviewConfirm"
      :mask-closable="false"
      :content="previewConfiremMessage"
      :type="defalut"
      preset="dialog"
      negative-text="취소"
      positive-text="확인"
      @positive-click="onGiftPreviewPositiveClick"
      @negative-click="onGiftPreviewNegativeClick"
    >
    </NModal>
    <NModal
      v-model:show="isDeleteConfirm"
      :mask-closable="false"
      :content="deleteConfirmMessage"
      :type="deleteConfirmType"
      preset="dialog"
      negative-text="취소"
      positive-text="확인"
      @positive-click="onDeletePositiveClick"
      @negative-click="onDeleteNegativeClick"
    >
    </NModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { getCallable, firebaseStorage, storageRef } from "@/config/env";
import { uploadBytes } from "firebase/storage";
import {
  NSpace,
  NCheckbox,
  NCard,
  NSelect,
  NButton,
  NInput,
  NModal,
  NRadio,
  NRadioGroup,
  NUpload,
  UploadInst,
  commonDark,
  NInputNumber,
} from "naive-ui";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
const addContent = getCallable("addContent");
const addPreviewContent = getCallable("addPreviewContent");
const addGiftPreviewContent = getCallable("addGiftPreviewContent");
const modifyContent = getCallable("modifyContent");
const modifyPreviewContent = getCallable("modifyPreviewContent");
const modifyGiftPreviewContent = getCallable("modifyGiftPreviewContent");
const deleteContent = getCallable("deleteContent");

const getStorys = getCallable("getStorysForAdmin");
const getFileName = getCallable("getFileName");
const getContentVoiceType = getCallable("getContentVoiceType");

export default defineComponent({
  name: "AddContent",
  props: ["isAddContent", "selectedItem"],
  components: {
    NRadio,
    NCheckbox,
    NSpace,
    NCard,
    NSelect,
    NButton,
    NInput,
    NModal,
    NRadioGroup,
    NUpload,
    NInputNumber,
  },
  watch: {
    isAddContent: function (newIsAddContent: any) {
      this.sItem = this.selectedItem;
      this.isAdd = this.sItem == undefined;
      if (this.isAdd) {
        this.submitButtonText = "등록";
        this.clearEditor();
      } else {
        this.submitButtonText = "수정";
        this.setDataEditor();
      }
    },
  },
  data() {
    return {
      section1: common.Section1,
      preview: common.preview,
      giftPreview: common.giftPreview,
      submitButtonText: "등록",
      resetUploadFileButtonText: "재등록",
      isShowUploadFile: true as boolean,
      isAdd: true as boolean,
      sItem: undefined as any,
      contentTypeValue: "O",
      depth1Value: this.section1 as any,
      depth2Value: "",
      depth3Value: "",
      isAlert: false,
      alertMessage: "",
      alertType: "",
      isPreviewConfirm: false,
      isGiftPreviewConfirm: false,
      previewConfiremMessage: "",
      author: "작성자",
      contentTitle: "",
      contentKo: "",
      contentFileUrlKo: "",
      voiceTypeKo: undefined,
      contentEn: "",
      contentFileUrlEn: "",
      voiceTypeEn: undefined,
      pagination: { pageSize: 2 },
      ageValues: [
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        30,
        40,
        50,
        60,
        70,
      ],
      minAgeValues: [] as any,
      minAgeValue: 5,
      maxAgeValues: [] as any,
      maxAgeValue: 70,
      showList: [] as any,
      depth1: [] as any,
      depth2: [] as any,
      depth3: [] as any,
      storyList: [] as any,
      fileKo: undefined as any,
      fileEn: undefined as any,
      contentType: ["O", "X"] as any,
      isDeleteConfirm: false,
      deleteConfirmMessage: "",
      deleteConfirmType: "error",
      voiceTypeKoValues: [] as any,
      voiceTypeEnValues: [] as any,
      isDisableVoiceType: false,
      contentKoSpeakingRate: 1,
      contentKoPitch: 0,
      contentEnSpeakingRate: 1,
      contentEnPitch: 0,
    };
  },
  setup() {
    const uploadKoRef = ref<UploadInst | null>(null);
    const uploadEnRef = ref<UploadInst | null>(null);
    return {
      uploadKo: uploadKoRef,
      uploadEn: uploadEnRef,
    };
  },
  mounted() {
    for (let age of this.ageValues) {
      this.minAgeValues.push({
        label: age + "세",
        value: age,
      });
      this.maxAgeValues.push({
        label: age + "세",
        value: age,
      });
    }
    this.getStorys();
    this.getContentVoiceType();
  },
  methods: {
    fileReUpload() {
      if (this.isShowUploadFile) {
        this.resetUploadFileButtonText = "재등록";
        this.isShowUploadFile = false;
      } else {
        this.resetUploadFileButtonText = "재등록 취소";
        this.isShowUploadFile = true;
        this.selectContentType();
      }
    },
    clearEditor() {
      this.isShowUploadFile = true;
      this.depth1Value = this.section1;
      this.depth2Value = "";
      this.depth3Value = "";
      this.contentTitle = "";
      this.contentTypeValue = "O";
      this.contentKo = "";
      this.contentEn = "";
      this.voiceTypeKo = undefined;
      this.voiceTypeEn = undefined;
      this.contentKoSpeakingRate = 1;
      this.contentKoPitch = 0;
      this.contentEnSpeakingRate = 1;
      this.contentEnPitch = 0;
      this.selectContentType();
      this.selectSection();
      this.selectContentVoiceType();
    },

    setDataEditor() {
      console.log(this.sItem);
      if (this.sItem != undefined) {
        let d1Value = this.sItem.depth1;
        if (this.sItem.SK == common.previewContent) d1Value = common.preview;
        else if (this.sItem.SK == common.giftPreviewContent) d1Value = common.giftPreview;

        this.depth1Value = d1Value;
        this.depth2Value = this.sItem.depth2;
        this.selectSection();
        this.depth3Value = this.sItem.depth3;
        this.contentTitle = this.sItem.title;
        if (d1Value != common.preview && d1Value != common.giftPreview) {
          this.contentTypeValue = this.sItem.isCreate ? "O" : "X";
          this.isShowUploadFile = this.sItem.isCreate;
        }
        this.contentKo = this.sItem.contentKo;
        this.contentEn = this.sItem.contentEn;
        this.contentFileUrlKo = this.sItem.contentFileUrlKo;
        this.contentFileUrlEn = this.sItem.contentFileUrlEn;
        this.voiceTypeKo = this.sItem.contentKoVoiceType;
        this.voiceTypeEn = this.sItem.contentEnVoiceType;
        this.contentKoSpeakingRate =
          this.sItem.contentKoSpeakingRate == undefined
            ? 1
            : this.sItem.contentKoSpeakingRate;
        this.contentEnSpeakingRate =
          this.sItem.contentEnSpeakingRate == undefined
            ? 1
            : this.sItem.contentEnSpeakingRate;
        this.contentKoPitch =
          this.sItem.contentKoPitch == undefined ? 0 : this.sItem.contentKoPitch;
        this.contentEnPitch =
          this.sItem.contentEnPitch == undefined ? 0 : this.sItem.contentEnPitch;

        this.contentTypeValue == "O"
          ? (this.isDisableVoiceType = false)
          : (this.isDisableVoiceType = true);
      }
    },
    selectContentType() {
      if (this.contentTypeValue == "O") {
        this.uploadEn?.clear();
        this.uploadKo?.clear();
        this.fileEn = undefined;
        this.fileKo = undefined;
        this.isDisableVoiceType = false;
      } else {
        this.isDisableVoiceType = true;
      }
    },
    selectSection() {
      this.depth2 = [];
      let isFirst = true;
      for (let item of this.storyList) {
        if (item.depth1 == this.depth1Value && item.depth3 == undefined) {
          if (isFirst && this.isAdd) {
            isFirst = false;
            this.depth2Value = item.depth2;
          }
          this.depth2.push(item);
        }
      }

      if (this.depth1Value == this.section1 || this.depth1Value == this.preview) {
        this.contentTypeValue = "O";
        this.isDisableVoiceType = true;
      } else {
        if (this.depth1Value == common.giftPreview) {
          this.isDisableVoiceType = true;
        } else {
          this.isDisableVoiceType = false;
        }
      }

      this.selectCategory1();
    },
    selectCategory1() {
      this.depth3 = [];
      this.depth3Value = "";
      let isFirst = true;
      for (let item of this.storyList) {
        if (
          item.depth1 == this.depth1Value &&
          item.depth2 == this.depth2Value &&
          item.depth3 != undefined
        ) {
          if (isFirst && this.isAdd) {
            isFirst = false;
            this.depth3Value = item.depth3;
          }
          this.depth3.push(item);
        }
      }
      if (
        this.depth1Value == this.section1 ||
        this.depth1Value == this.preview ||
        this.depth1Value == common.giftPreview
      ) {
        this.isDisableVoiceType = true;
        if (this.depth1Value == this.section1 && this.depth2Value == common.AI) {
          this.isDisableVoiceType = false;
        }
      } else {
        if (this.contentTypeValue == "O") {
          this.isDisableVoiceType = false;
        } else {
          this.isDisableVoiceType = true;
        }
      }
      console.log(this.depth2Value);
    },
    selectContentVoiceType() {
      if (this.voiceTypeKoValues.length > 0) {
        this.voiceTypeKo = this.voiceTypeKoValues[0].value;
      }

      if (this.voiceTypeEnValues.length > 0) {
        this.voiceTypeEn = this.voiceTypeEnValues[0].value;
      }

      this.contentKoSpeakingRate = 1;
      this.contentEnSpeakingRate = 1;
      this.contentKoPitch = 0;
      this.contentEnPitch = 0;
    },
    contentKoSeclect(e: any) {
      console.log(e);
      this.fileKo = e.file;
    },
    contentEnSeclect(e: any) {
      console.log(e);
      this.fileEn = e.file;
    },
    onPositiveClick() {
      this.isAlert = false;
    },
    onPreviewPositiveClick() {
      this.addContent(true, false);
      this.isPreviewConfirm = false;
    },

    onPreviewNegativeClick() {
      this.isPreviewConfirm = false;
    },
    onGiftPreviewPositiveClick() {
      this.addContent(false, true);
      this.isGiftPreviewConfirm = false;
    },

    onGiftPreviewNegativeClick() {
      this.isGiftPreviewConfirm = false;
    },

    onDeletePositiveClick() {
      this.delete();
      this.isDeleteConfirm = false;
    },
    onDeleteNegativeClick() {
      this.isDeleteConfirm = false;
    },
    checkAddContent() {
      let isPreview = false;
      let isGiftPreview = false;
      if (this.depth1Value == undefined) {
        this.alertMessage = "Section을 선택해주세요.";
        this.alertType = "error";
        this.isAlert = true;
        return;
      }
      if (this.depth1Value == this.preview) {
        isPreview = true;
      } else if (this.depth1Value == this.giftPreview) {
        isGiftPreview = true;
      } else {
        if (this.depth2Value == undefined) {
          this.alertMessage = "Category를 선택해주세요.";
          this.alertType = "error";
          this.isAlert = true;
          return;
        }
        if (this.contentTitle == undefined) {
          this.alertMessage = "주제를 입력해주세요.";
          this.alertType = "error";
          this.isAlert = true;
          return;
        }
      }

      if ((isPreview || isGiftPreview) && this.isAdd) {
        this.previewConfiremMessage =
          "이미 등록된 preview가 있다면 작성된 내용으로 수정되어 저장됩니다.";

        if (isPreview) this.isPreviewConfirm = true;
        if (isGiftPreview) this.isGiftPreviewConfirm = true;
      } else {
        this.addContent(isPreview, isGiftPreview);
      }
    },
    addContent(isPreview: any, isGiftPreview: any) {
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        let uploadFile = undefined;

        let data = {
          depth1: this.depth1Value,
          depth2: this.depth2Value,
          depth3: this.depth3Value,
          minAge: this.minAgeValue,
          maxAge: this.maxAgeValue,
          title: this.contentTitle,
          isCreate: this.contentTypeValue == "O" ? true : false,
          contentKo: this.contentKo,
          contentEn: this.contentEn,
          contentKoVoiceType: this.isDisableVoiceType ? undefined : this.voiceTypeKo,
          contentEnVoiceType: this.isDisableVoiceType ? undefined : this.voiceTypeEn,
          contentKoSpeakingRate: this.isDisableVoiceType
            ? undefined
            : this.contentKoSpeakingRate,
          contentKoPitch: this.isDisableVoiceType ? undefined : this.contentKoPitch,
          contentEnSpeakingRate: this.isDisableVoiceType
            ? undefined
            : this.contentEnSpeakingRate,
          contentEnPitch: this.isDisableVoiceType ? undefined : this.contentEnPitch,
        } as any;

        data.author = this.author;
        data.contentFileUrlKo = "";
        data.contentFileUrlEn = "";
        console.log(data);

        if (this.isAdd) {
          let fileNameResult = (await getFileName({
            depth1: this.depth1Value,
            depth2: this.depth2Value,
            depth3: this.depth3Value,
          })) as any;

          if (fileNameResult.data.error) {
            this.alertMessage = "파일 저장 실패.";
            this.alertType = "error";
            this.isAlert = true;
            return;
          }

          data.fileName = fileNameResult.data.data.fileName;
          if (this.depth1Value != this.section1 && this.fileKo != undefined) {
            let fileName = fileNameResult.data.data.fileName;
            let path =
              common.makeFilePath(this.depth1Value, this.depth2Value, this.depth3Value) +
              "/ko";
            data.contentFileUrlKo = await common.uploadFile(
              this.fileKo.file,
              fileName,
              path
            );
          }
          if (this.depth1Value != this.section1 && this.fileEn != undefined) {
            let fileName = fileNameResult.data.data.fileName;
            let path =
              common.makeFilePath(this.depth1Value, this.depth2Value, this.depth3Value) +
              "/en";
            data.contentFileUrlEn = await common.uploadFile(
              this.fileEn.file,
              fileName,
              path
            );
          }
          let addContentFunction = addContent;
          if (isPreview) addContentFunction = addPreviewContent;
          else if (isGiftPreview) addContentFunction = addGiftPreviewContent;

          addContentFunction(data)
            .then(async (result: any) => {
              if (result.data.error) {
                this.alertMessage = "등록 실패";
                this.alertType = "error";
                this.isAlert = true;
              } else {
                this.alertMessage = "등록 성공";
                this.alertType = "success";
                this.isAlert = true;
                this.hide();
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.$emit("changeLoadingStatus", false);
            });
        } else {
          data.id = this.sItem.SK;
          data.fileName = this.sItem.fileName;
          if (
            this.sItem.contentFileUrlKo != undefined &&
            this.sItem.contentFileUrlKo != ""
          )
            await common.deleteFile(this.sItem.contentFileUrlKo);
          if (
            this.sItem.contentFileUrlEn != undefined &&
            this.sItem.contentFileUrlEn != ""
          )
            await common.deleteFile(this.sItem.contentFileUrlEn);

          if (
            this.depth1Value != this.section1 &&
            this.fileKo != undefined &&
            this.isShowUploadFile
          ) {
            let path =
              common.makeFilePath(this.depth1Value, this.depth2Value, this.depth3Value) +
              "/ko";
            data.contentFileUrlKo = await common.uploadFile(
              this.fileKo.file,
              data.fileName,
              path
            );
          }

          if (
            this.depth1Value != this.section1 &&
            this.fileEn != undefined &&
            this.isShowUploadFile
          ) {
            let path =
              common.makeFilePath(this.depth1Value, this.depth2Value, this.depth3Value) +
              "/en";
            data.contentFileUrlEn = await common.uploadFile(
              this.fileEn.file,
              data.fileName,
              path
            );
          }

          let modifyContentFunction = modifyContent;
          if (isPreview) modifyContentFunction = modifyPreviewContent;
          else if (isGiftPreview) modifyContentFunction = modifyGiftPreviewContent;

          modifyContentFunction(data)
            .then(async (result: any) => {
              if (result.data.error) {
                this.alertMessage = "수정 실패";
                this.alertType = "error";
                this.isAlert = true;
              } else {
                this.alertMessage = "수정 성공";
                this.alertType = "success";
                this.isAlert = true;
                this.hide();
              }
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.$emit("changeLoadingStatus", false);
            });
        }
      });
    },
    hide() {
      this.$emit("hide");
    },
    confirmDelete() {
      this.deleteConfirmMessage = "삭제 하시겠습니까?";
      this.isDeleteConfirm = true;
    },
    delete() {
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        deleteContent({
          id: this.sItem.SK,
        })
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "삭제 실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              this.alertMessage = "삭제 성공";
              this.alertType = "success";
              this.isAlert = true;
              this.hide();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    getStorys() {
      // this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        getStorys({})
          .then(async (result: any) => {
            let depth1Set = new Set();
            this.storyList = result.data.data.Items;
            for (let item of result.data.data.Items) {
              depth1Set.add(item.depth1);
            }

            console.log(depth1Set);
            let depth1List = Array.from(depth1Set).sort();
            for (let d1 of depth1List) {
              this.depth1.push({
                key: d1,
              });
            }
            this.depth1.push({
              key: common.preview,
            });

            this.depth1.push({
              key: common.giftPreview,
            });

            this.selectSection();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // this.$emit("changeLoadingStatus", false);
          });
      });
    },
    getContentVoiceType() {
      // this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        getContentVoiceType({})
          .then(async (result: any) => {
            let results = result.data.data;

            for (let item of results) {
              let label = item.name + " / " + item?.ssmlGender;
              if (item.languageCodes == constant.lang_codes.KO) {
                this.voiceTypeKoValues.push({
                  label: label,
                  value: item.name,
                });
              } else {
                this.voiceTypeEnValues.push({
                  label: label,
                  value: item.name,
                });
              }
            }

            this.selectContentVoiceType();
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // this.$emit("changeLoadingStatus", false);
          });
      });
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.add_content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
