<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 300px; font-weight: bold">일레븐랩스 계정 관리 > 계정 등록</div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>
      <n-space style="width: 1400px; min-width: 1400px; height: auto">
        <NCard style="margin-top: 20px; margin-left: 100px; width: 1400px; min-width: 1400px; height: 100%">
          <n-space item-style="display: flex;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">아이디</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="id"
              style="width: 1000px; height: 30px; text-align: left"
            />
          </n-space>
          <div class="add_content_delimiter_line"></div>

          <n-space item-style="display: flex; ">
            <div style="font-size: 20px; width: 150px; font-weight: bold">API_KEY</div>
            <NInput
              type="text"
              placeholder=""
              v-model:value="apiKey"
              style="width: 1000px; height: ; text-align: left"
            />
          </n-space>

          <n-space style="margin-top: 50px" justify="center">
            <NButton dashed style="width: 250px; height: 30px; border: none" type="warning" v-on:click="hide">
              취소
            </NButton>

            <NButton dashed style="width: 250px; height: 30px; border: none" type="success" v-on:click="checkAddParam">
              {{ submitButtonText }}
            </NButton>
          </n-space>
        </NCard>
      </n-space>
    </div>
    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { getCallable, firebaseStorage, storageRef } from '@/config/env';
import { uploadBytes } from 'firebase/storage';
import {
  NSpace,
  NCheckbox,
  NCard,
  NSelect,
  NButton,
  NInput,
  NModal,
  NRadio,
  NRadioGroup,
  NUpload,
  UploadInst,
  commonDark,
} from 'naive-ui';
import * as common from '@/assets/common/common';

const addElevenLabsKey = getCallable('addElevenLabsKey');

export default defineComponent({
  name: 'addNotice',
  props: ['isAddElevenLabs', 'selectedItem'],
  components: {
    NRadio,
    NCheckbox,
    NSpace,
    NCard,
    NSelect,
    NButton,
    NInput,
    NModal,
    NRadioGroup,
    NUpload,
  },
  watch: {
    isAddElevenLabs: function (newIsAddElevenLabs: any) {
      this.clearEditor();
    },
  },
  data() {
    return {
      id: '',
      apiKey: '',
      submitButtonText: '등록',
      isAlert: false,
      alertMessage: '',
      alertType: '',
    };
  },
  setup() {},
  mounted() {},
  methods: {
    clearEditor() {
      this.id = '';
      this.apiKey = '';
    },

    onPositiveClick() {
      this.isAlert = false;
    },

    checkAddParam() {
      if (this.id == undefined) {
        this.alertMessage = '아이디를 입력해주세요.';
        this.alertType = 'error';
        this.isAlert = true;
        return;
      }
      if (this.apiKey == undefined) {
        this.alertMessage = 'API_KEY를 입력해주세요.';
        this.alertType = 'error';
        this.isAlert = true;
        return;
      }
      this.addELKey();
    },
    addELKey() {
      this.$emit('changeLoadingStatus', true);
      common.asyncFunction(async () => {
        let data = {
          id: this.id,
          API_KEY: this.apiKey,
        } as any;

        addElevenLabsKey(data)
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = '등록 실패';
              this.alertType = 'error';
              this.isAlert = true;
            } else {
              this.alertMessage = '등록 성공';
              this.alertType = 'success';
              this.isAlert = true;
              this.hide();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit('changeLoadingStatus', false);
          });
      });
    },
    hide() {
      this.$emit('hide');
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.add_content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
