<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 300px; font-weight: bold">
              프로모션 코드 > 코드 목록
            </div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>

      <NCard
        style="
          margin-top: 20px;
          margin-left: 100px;
          width: 100%;

          height: 100%;
        "
      >
        <p>Promotion Name: {{ promoId }}</p>
        <div class="outer" style="height: 620px; margin-top: 50px">
          <n-data-table
            class="inner"
            style="width: 100%; height: 620px"
            ref="table"
            :columns="columns"
            :data="showList"
            :pagination="pagination"
            :max-height="520"
            striped
          />
        </div>
        <NButton
          dashed
          style="width: 250px; height: 30px; border: none"
          type="warning"
          v-on:click="hide"
        >
          뒤로
        </NButton>
      </NCard>
    </div>

    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
  </div>
</template>

<script lang="ts">
import { h, defineComponent, ref } from "vue";
import { getCallable, firebaseStorage } from "@/config/env";
import { NSpace, NCard, NButton, NIcon, NModal, NDataTable, commonLight } from "naive-ui";
import Loading from "@/components/Loading.vue";
import * as common from "@/assets/common/common";
import * as constant from "@/assets/constant";
import axios from "axios";
import type { UploadInst, FormInst } from "naive-ui";

const getPromotionCodes = getCallable("getPromotionCodes");

export default defineComponent({
  name: "PromotionDetails",
  props: ["isPromotionDetails", "selectedItemDetails"],
  components: {
    NSpace,
    NDataTable,
    NCard,
    NButton,
    NIcon,
    NModal,

    Loading,
  },
  watch: {
    isPromotionDetails: function (newIsDetails: any) {
      if (this.selectedItemDetails != null) {
        console.log(this.selectedItemDetails);
        this.sItem = this.selectedItemDetails;
        this.promoId = this.sItem.name ?? "";
        this.getPromotionCodeList();
      }
    },
  },
  data() {
    return {
      promoId: "",
      isAdd: true as boolean,
      isAlert: false as boolean,
      alertType: "",
      alertMessage: "",
      isLoading: false,
      sItem: undefined as any,
      columns: [
        {
          title: "코드",
          key: "code",
          sorter: "default",
        },
        {
          title: "innaID",
          key: "innaId",
          sorter: "default",
        },
      ],
      pagination: { pageSize: 50 },
      baseList: [] as any,
      showList: [] as any,
    };
  },
  setup() {},
  beforeUnmount() {},
  mounted() {},
  methods: {
    onPositiveClick() {
      this.isAlert = false;
    },
    hide() {
      this.$emit("hide");
    },
    getPromotionCodeList() {
      this.baseList = [];
      this.showList = [];
      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        let data = {
          id: this.sItem.SK,
        } as any;

        getPromotionCodes(data)
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              let i = 0;
              for (let item of result.data.data.Items) {
                item.index = i++;
                this.baseList.push(item);
              }
              this.showList = this.baseList;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}
</style>
