import { createStore } from "vuex";

export default createStore({
  state: {
    user: {
    },
    affiliateUserInfo: {
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getAffiliateUserInfo(state) {
      return state.affiliateUserInfo;
    }
  },
  mutations: {
    setUser: (state, user) => {
      state.user = user;
    },
    setAffiliateUserInfo: (state, user) => {
      state.affiliateUserInfo = user;
    },

  },
  actions: {
  },
  modules: {},
});
