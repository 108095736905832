<template>
  <div class="main_div">
    <Loading v-show="isLoading" />
    <NCard v-show="isLoading == false" class="content_div">
      <div class="login_title">INNA Management System</div>
      <div class="delimiter_line"></div>
      <!-- <div style="margin-top: 20px; text-align: center; vertical-align: middle">
        <div style="display: inline-block; width: 50px">아이디</div>
        <NInput
          type="text"
          style="margin-left: 50px; width: 200px; height: 30px"
        />
      </div>
      <div style="margin-top: 20px; text-align: center; vertical-align: middle">
        <div style="display: inline-block">비밀번호</div>
        <NInput
          placeholder="비밀번호를 입력해주세요."
          type="password"
          style="margin-left: 50px; width: 200px; height: 30px"
        />
      </div> -->
      <div style="margin-top: 20px; text-align: center; vertical-align: middle">
        <NButton style="width: 250px; height: 30px; background: yellow; border: none" v-on:click="kakaoLogin">
          kakao Talk LOGIN
        </NButton>
        <!-- <NButton
          style="width: 250px; height: 30px"
          v-on:click="kakaoGetUserInfo"
        >
          유저 정보
        </NButton> -->
        <!-- <NButton style="width: 250px; height: 30px" v-on:click="join">
          회원 가입
        </NButton>
        <NButton style="width: 250px; height: 30px" v-on:click="checkAuth">
          체크 AUTH
        </NButton>
        <NButton style="width: 250px; height: 30px" v-on:click="existMember">
          가입 체크
        </NButton> -->
        <!-- <NButton style="width: 250px; height: 30px" v-on:click="kakaoLogout">
          로그아웃
        </NButton> -->
      </div>
      <div class="delimiter_line"></div>
    </NCard>
    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { firebaseAuth, getCallable, Kakao } from '@/config/env';
import { signInWithCustomToken, signOut } from 'firebase/auth';
import { kakaoConfig } from '@/config/kakao_env';
import * as apiService from '@/service/apiService';
import * as common from '@/assets/common/common';
import * as auth from '@/assets/common/auth';
import Loading from '@/components/Loading.vue';
import * as constant from '@/assets/constant';
import { NButton, NInput, NCard, NModal } from 'naive-ui';

const kakao = Kakao;

export default defineComponent({
  name: 'Login',
  components: {
    NCard,
    NInput,
    NButton,
    NModal,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      userInfo: undefined as any,
      isGetKakaoInfo: false,
      isAlert: false,
      alertMessage: '',
      alertType: '',
    };
  },
  async mounted() {
    this.changeLoadingStatus(true);
    common.asyncFunction(async () => {
      await firebaseAuth.authStateReady();
      if (this.$route.query.code != undefined) {
        let code = this.$route.query.code;
        let result = (await apiService.default.getToken({
          grant_type: kakaoConfig.grantType,
          client_id: kakaoConfig.restApiKey,
          redirect_uri: kakaoConfig.reDirectUri,
          code: code,
        })) as any;
        if (result.status == 200) {
          let setAccessTokenResult = await kakao.Auth.setAccessToken(result.data.access_token);
          let kakaoUserInfo: any = await auth.kakaoGetUserInfo();
          console.log(kakaoUserInfo);
          this.$store.commit('setAffiliateUserInfo', kakaoUserInfo);
          if (kakaoUserInfo != undefined && kakaoUserInfo.id != undefined) {
            let joinResult = (await auth.join(kakaoUserInfo.id, kakaoUserInfo.properties.nickname)) as any;

            if (joinResult.type == constant.login_type.LOGIN) await this.checkAuth(false);
            else if (joinResult.type == constant.login_type.JOIN) await this.isJoinAdmin(joinResult);
            else if (joinResult.type == constant.login_type.LOCK) await this.isJoinAdmin(joinResult);
            else if (joinResult.type == constant.login_type.ERROR) await this.isJoinAdmin(joinResult);
          }
        } else {
          this.$router.replace({ path: this.$route.path, query: {} });
        }
      } else {
        await this.checkAuth(true);
      }
      this.changeLoadingStatus(false);
    });
  },
  methods: {
    changeLoadingStatus(isLoading: boolean) {
      this.isLoading = isLoading;
    },
    async kakaoLogin() {
      await kakao.Auth.authorize({
        redirectUri: kakaoConfig.reDirectUri,
        scope: 'openid',
        prompt: 'select_account',
      });
    },
    async checkAuth(isCheck: any) {
      let currentUser = auth.getCurrentUser();
      if (currentUser != null) {
        (this as any).$router.push('/Home');
      } else {
        if (!isCheck) {
          this.alertMessage = '로그인 실패';
          this.alertType = 'error';
          this.isAlert = true;
        }
      }
    },
    async isJoinAdmin(joinResult: any) {
      if (joinResult.type == 'JOIN') {
        this.alertMessage = '가입 요청 완료! 아이디(uid)  : ' + joinResult.uid + ' 관리자에게 연락해주세요.';
        this.alertType = 'info';
      } else if (joinResult.type == 'LOCK') {
        this.alertMessage = '가입 승인되지 않았습니다. 아이디(uid) : ' + joinResult.uid + ' 관리자에게 연락해주세요.';
        this.alertType = 'error';
      } else if (joinResult.type == 'ERROR') {
        this.alertMessage = '로그인 에러! 잠시 후 시도해주세요.';
        this.alertType = 'error';
      }
      this.isAlert = true;
    },
    onPositiveClick() {
      this.isAlert = false;
    },
  },
});
</script>

<style>
.main_div {
  width: 100%;
}
.content_div {
  width: 500px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login_title {
  font-size: 30px;
}
.delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
