<template>
  <div class="main_div">
    <div style="height: auto">
      <n-space>
        <NCard style="margin-top: 20px; margin-left: 80px; border: 0px; min-width: 800px">
          <n-space item-style="display: flex;" align="center">
            <div style="font-size: 20px; width: 250px; font-weight: bold">
              의견 접수 > 답변 등록
            </div>
          </n-space>
          <div class="content_delimiter_line"></div>
        </NCard>
      </n-space>
      <n-space style="width: 1400px; min-width: 1400px; height: auto">
        <NCard
          style="
            margin-top: 20px;
            margin-left: 100px;
            width: 1400px;
            min-width: 1400px;
            height: 100%;
          "
        >
          <n-space item-style="display: flex;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">카테고리</div>
            <NInput
              defalut-value="feedbackCategory"
              type="text"
              placeholder=""
              v-model:value="feedbackCategory"
              style="width: 1000px; height: 30px; text-align: left"
              readonly="true"
            />
          </n-space>
          <div class="add_content_delimiter_line_transparent"></div>
          <n-space item-style="display: flex; height:200px;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">내용</div>
            <NInput
              type="textarea"
              placeholder=""
              v-model:value="content"
              style="width: 1000px; height: 200px; text-align: left"
              readonly="true"
            />
          </n-space>
          <div class="add_content_delimiter_line"></div>
          <n-space item-style="display: flex; height:500px;">
            <div style="font-size: 20px; width: 150px; font-weight: bold">답변</div>
            <NInput
              type="textarea"
              placeholder=""
              v-model:value="answer"
              style="width: 1000px; height: 500px; text-align: left"
            />
          </n-space>

          <n-space style="margin-top: 50px" justify="center">
            <NButton
              dashed
              style="width: 250px; height: 30px; border: none"
              type="warning"
              v-on:click="hide"
            >
              취소
            </NButton>

            <NButton
              dashed
              style="width: 250px; height: 30px; border: none"
              type="success"
              v-on:click="addAnswer"
            >
              {{ submitButtonText }}
            </NButton>
          </n-space>
        </NCard>
      </n-space>
    </div>
    <NModal
      v-model:show="isAlert"
      :mask-closable="false"
      :content="alertMessage"
      :type="alertType"
      preset="dialog"
      title="Dialog"
      positive-text="확인"
      @positive-click="onPositiveClick"
    >
    </NModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { getCallable, firebaseStorage, storageRef } from "@/config/env";
import { uploadBytes } from "firebase/storage";
import {
  NSpace,
  NCheckbox,
  NCard,
  NSelect,
  NButton,
  NInput,
  NModal,
  NRadio,
  NRadioGroup,
  NUpload,
  UploadInst,
  commonDark,
} from "naive-ui";
import * as common from "@/assets/common/common";

const addAnswer = getCallable("modifyFeedback");

export default defineComponent({
  name: "AddAnswer",
  props: ["isAddAnswer", "selectedItem"],
  components: {
    NRadio,
    NCheckbox,
    NSpace,
    NCard,
    NSelect,
    NButton,
    NInput,
    NModal,
    NRadioGroup,
    NUpload,
  },
  watch: {
    isAddAnswer: function (newIsAddAnswer: any) {
      this.sItem = this.selectedItem;
      this.setDataEditor();
    },
  },
  data() {
    return {
      sItem: undefined as any,
      content: "",
      answer: "",
      submitButtonText: "저장",
      resetUploadFileButtonText: "재등록",
      isAlert: false,
      alertMessage: "",
      alertType: "",
      feedbackCategory: "",
      feedbackId: "",
      uid: "",
    };
  },
  setup() {},
  mounted() {},
  methods: {
    clearEditor() {
      this.answer = "";
    },

    onPositiveClick() {
      this.isAlert = false;
    },
    setDataEditor() {
      console.log(this.sItem);
      if (this.sItem != undefined) {
        this.feedbackCategory = this.sItem.parseType;
        this.content = this.sItem.content;
        this.feedbackId = this.sItem.SK;
        this.answer = this.sItem.answer;
        this.uid = this.sItem.uid;
      }
    },

    addAnswer() {
      if (this.answer == "" || this.answer == undefined) {
        this.alertMessage = "답변을 등록해주세요.";
        this.alertType = "error";
        this.isAlert = true;
        return;
      }

      this.$emit("changeLoadingStatus", true);
      common.asyncFunction(async () => {
        let data = {
          uid: this.uid,
          id: this.feedbackId,
          status: "20",
          answer: this.answer,
        } as any;

        addAnswer(data)
          .then(async (result: any) => {
            if (result.data.error) {
              this.alertMessage = "등록 실패";
              this.alertType = "error";
              this.isAlert = true;
            } else {
              this.alertMessage = "등록 성공";
              this.alertType = "success";
              this.isAlert = true;

              this.hide();
              this.$emit("answered");
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.$emit("changeLoadingStatus", false);
          });
      });
    },
    hide() {
      this.clearEditor();
      this.$emit("hide");
    },
  },
});
</script>

<style scope>
.outer {
  position: relative;
}
.inner {
  position: absolute;
}
.add_content_delimiter_line {
  border: lightgrey 2px solid;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.add_content_delimiter_line_transparent {
  border: transparent 2px solid;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
