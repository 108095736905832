import * as firebaseConfigJson from "./firebaseConfig.json";

const firebaseConfigs = firebaseConfigJson;

export const firebaseConfig = {
  apiKey: firebaseConfigs.apiKey,
  authDomain: firebaseConfigs.authDomain,
  projectId: firebaseConfigs.projectId,
  storageBucket: firebaseConfigs.storageBucket,
  messagingSenderId: firebaseConfigs.messagingSenderId,
  appId: firebaseConfigs.appId,
  region: firebaseConfigs.region,
};
export const useEmulator = false;
export const storageUrl = "gs://inna-prod.appspot.com"
export const promotionFileUploadUrl = "https://asia-northeast3-inna-prod.cloudfunctions.net/uploadPromotionCsv"